import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { login, getCalculationByUserId } from "../axios/index";
import { useNavigate } from "react-router-dom";
import background from "../assets/images/background.jpg";
import logo from "../assets/images/eraLogo.png";
import { errorToast } from "../functions/toast";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const loginMutation = useMutation((formData) => login(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["me"]);
    },
    onError: (error) => {
      if (error?.response?.data?.message) {
        errorToast(error.response.data.message);
      } else {
        errorToast("Bir hata oluştu, lütfen yönetici ile iletişime geçin.");
      }
    },
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await loginMutation.mutateAsync(formData);
    queryClient.setQueryData(["me"], data);
    if (data.user.isAdmin) {
      navigate("/admin");
    } else {
      const { data: initialCalculationsData } = await getCalculationByUserId(
        data.user._id
      );
      console.log("initial calc data: ", initialCalculationsData);
      navigate("/exCalc", { state: { initialCalculationsData } });
    }
  };
  return (
    <div
      className="color-overlay d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Form
        onSubmit={onSubmit}
        className="form-signin w-100 m-auto rounded p-5 p-sm-4 w-25 "
      >
        <img
          src={logo}
          class="bd-placeholder-img border border-primary"
          width="80"
        />
        <div className="h2 ">
          <Form.Text style={{ color: "#097969", fontWeight: 600 }}>
            Giriş Yap
          </Form.Text>
        </div>
        <Form.Group className="mb-3  h5" controlId="formBasicEmail">
          <Form.Label className="d-flex justify-content-start">
            Email Adresi
          </Form.Label>
          <Form.Control
            className=""
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
            //type="email"
            placeholder="Enter email"
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3  h5" controlId="formBasicPassword">
          <Form.Label className="d-flex justify-content-start">
            Şifre
          </Form.Label>
          <Form.Control
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
            type="password"
            placeholder="Password"
          />
        </Form.Group>

        <Button
          className="w-100 shadow"
          style={{ backgroundColor: "#097969" }}
          type="submit"
        >
          Giriş Yap
        </Button>
        <Button
          onClick={() => navigate("/")}
          className="w-100 shadow mt-2"
          style={{
            backgroundColor: "#50C878",
            color: "#097969",
            fontWeight: 500,
          }}
          type="submit"
        >
          Geri Dön
        </Button>
      </Form>
    </div>
  );
};

export default Login;
