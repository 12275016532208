import React, { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { fetchFuelItems } from "../axios";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

const vehicleOptions = [
  { label: "Binek Arabası", value: "Binek Arabası" },
  { label: "Minivan/Van", value: "Minivan/Van" },
  { label: "Kamyon/TIR", value: "Kamyon/TIR" },
];

const fuelOptions = [
  { label: "Benzin", value: "Benzin" },
  { label: "Dizel", value: "Dizel" },
  { label: "Otogaz (LPG)", value: "LPG" },
];

const ElectricalVehicleSolution = ({ calculation }) => {
  const [fuelAmount, setFuelAmount] = useState(0);
  const [annualElectricPrice, setAnnualElectricPrice] = useState(0);
  const [annualFuelPrice, setAnnualFuelPrice] = useState(0);
  const [Z, setZ] = useState(0);
  const [vehicleSelected, setVehicleSelected] = useState([]);
  const [fuelSelected, setFuelSelected] = useState([]);
  const [askFuelType, setAskFuelType] = useState(false);
  const [stackedBarChartData, setStackedBarChartData] = useState();
  const [G, setG] = useState(0);
  const [reduction, setReduction] = useState(0);

  const overrideStrings = {
    selectAll: "Hepsini Seç",
    selectSomeItems: "Seçiniz...",
    search: "Ara",
    allItemsAreSelected: "Hepsi Seçildi",
  };

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
  );

  const stackedBarChartOptions = {
    plugins: {
      title: {
        display: true,
        text: "Yakıt Fiyat Karşılaştırması",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const { data, isFetching } = useQuery(["fuelItems"], () =>
    fetchFuelItems().then((res) => res.data)
  );

  useEffect(() => {
    vehicleSelected.filter(
      (item) => item.label === "Binek Arabası" || item.label === "Minivan/Van"
    ).length > 0
      ? setAskFuelType(true)
      : setAskFuelType(false);

    if (fuelSelected.length > 1) {
      setFuelSelected(fuelSelected.slice(1));
    }

    setFuelAmount(0);
    setAnnualElectricPrice(0);
    setReduction(0);
  }, [vehicleSelected, fuelSelected]);

  useEffect(() => {
    setStackedBarChartData({
      labels: [fuelSelected[0]?.label, "Elektrikli Araç Yakıt Maliyeti"],
      datasets: [
        {
          label: "Fiyat (TL)",
          data: [annualFuelPrice.toFixed(2), annualElectricPrice.toFixed(2)],
          backgroundColor: "rgba(75, 192, 192, 0.7)",
          borderColor: "rgba(75, 192, 192, 1)",
        },
      ],
    });
  }, [annualElectricPrice, annualFuelPrice]);

  return isFetching ? (
    <Spinner />
  ) : (
    <Card className="bg-light">
      <Card.Header>
        <h3>Araç/taşıt kullanımı</h3>
      </Card.Header>
      <Card.Body>
        <Form className="p-1">
          <Form.Group className="mb-3 h5">
            <Form.Label className="d-flex justify-content-start text-black">
              Şirket araç filonuzda aşağıdaki araçların hangileri yer
              almaktadır?
            </Form.Label>
            <div class="mb-2">
              <MultiSelect
                options={vehicleOptions}
                value={vehicleSelected}
                onChange={setVehicleSelected}
                labelledBy={"Select"}
                overrideStrings={overrideStrings}
              />
            </div>
          </Form.Group>

          {vehicleSelected.length === 1 &&
            vehicleSelected[0]?.label === "Kamyon/TIR" && (
              <div className="d-flex flex-column">
                <div className="d-flex align-content-center">
                  <h5 className="text-black">
                    Rota eniyilemesinin şirket araçlarının yakıt tüketiminde
                    %30’a varan düşüşler sağlayabileceğini biliyor muydunuz?
                    Eğer dilerseniz firma yetkililerimiz size bu konuda yardımcı
                    olmaktan mutluluk duyacaklardır.
                  </h5>
                </div>
              </div>
            )}
          {askFuelType && (
            <Form.Group className="mb-3 h5">
              <Form.Label className="d-flex justify-content-start text-black">
                Aracınızda hangi yakıtı kullanıyorsunuz?
              </Form.Label>
              <div class="mb-2">
                <MultiSelect
                  hasSelectAll={false}
                  options={fuelOptions}
                  value={fuelSelected}
                  onChange={setFuelSelected}
                  isCreatable={true}
                  labelledBy={"Select"}
                  overrideStrings={overrideStrings}
                />
              </div>
            </Form.Group>
          )}

          {fuelSelected?.length > 0 &&
            fuelSelected[0]?.label !== "Kamyon/TIR" && (
              <Form.Group className="mb-3 h5">
                <Form.Label className="d-flex justify-content-start text-black">
                  Araçlarınızın yıllık yakıt tüketimini litre cinsinden giriniz.
                </Form.Label>
                <Form.Control
                  value={fuelAmount}
                  onChange={(e) => {
                    setFuelAmount(e.target.value);
                    setAnnualFuelPrice(
                      e.target.value *
                        data.filter(
                          (item) => item.name === fuelSelected[0].value
                        )[0].price
                    );
                    setG(
                      fuelSelected[0].value === "Benzin"
                        ? 2.32 * e.target.value
                        : fuelSelected[0].value === "Dizel"
                        ? 2.69 * e.target.value
                        : 1.5 * e.target.value
                    );
                    document.getElementById("km-input").value = 0;
                    setZ(0);
                    setReduction(0);
                  }}
                  type="number"
                  className="text-black"
                />
              </Form.Group>
            )}
          {fuelAmount > 0 && (
            <Form.Group className="mb-3 h5">
              <Form.Label className="d-flex justify-content-start text-black">
                Araçlarınızın yıllık kat ettiği mesafeyi kilometre cinsinden
                yaklaşık olarak giriniz
              </Form.Label>
              <div
                className="btn-group mb-3 d-flex w-100 jusfify-content-between"
                role="group"
              >
                <Form.Control
                  id="km-input"
                  onChange={(e) => {
                    setZ(e.target.value);
                    setAnnualElectricPrice(
                      0.18 *
                        data?.filter((item) => item.name === "Elektrik")[0]
                          .price *
                        e.target.value
                    );
                    setReduction(G - 0.08 * Z);
                  }}
                  type="number"
                  className="text-black w-50"
                />
                <button
                  type="button"
                  className="btn btn-primary w-25"
                  onClick={(e) => {
                    document.getElementById("km-input").value = 0;
                    setZ(
                      fuelSelected[0].label === "Benzin"
                        ? 11.8 * fuelAmount
                        : fuelSelected[0].label === "Dizel"
                        ? 13.7 * fuelAmount
                        : 8.9 * fuelAmount
                    );
                    setAnnualElectricPrice(
                      0.18 *
                        data?.filter((item) => item.name === "Elektrik")[0]
                          .price *
                        (fuelSelected[0].label === "Benzin"
                          ? 11.8 * fuelAmount
                          : fuelSelected[0].label === "Dizel"
                          ? 13.7 * fuelAmount
                          : 8.9 * fuelAmount)
                    );

                    setReduction(G - 0.08 * Z);
                  }}
                >
                  Bilmiyorum
                </button>
              </div>
            </Form.Group>
          )}

          {annualElectricPrice < annualFuelPrice && annualElectricPrice > 0 && (
            <div class="col-md-12 mb-5 mt-5 mh-50" style={{ height: 350 }}>
              <Bar
                options={stackedBarChartOptions}
                data={stackedBarChartData}
              />
            </div>
          )}

          {reduction > 0 && (
            <>
              <div className="d-flex align-content-center">
                <h5 className="text-black" style={{ textAlign: "left" }}>
                  Elektrikli araç kullanılarak sağlanacak yıllık sera gazı salım
                  azaltımı (kg CO₂):{" "}
                </h5>
                <h5 className="text-black" style={{ marginLeft: 10 }}>
                  <strong>{reduction.toFixed(2)}</strong>
                </h5>
              </div>

              <hr />

              <div className="d-flex align-content-center">
                <h5 className="text-black">
                  Sera gazı salımında yüzdesel azalma:{" "}
                </h5>
                <h5 className="text-black" style={{ marginLeft: 10 }}>
                  <strong>
                    %
                    {(reduction / calculation.value) * 100 > 100
                      ? 100
                      : ((reduction / calculation.value) * 100).toFixed(2)}
                  </strong>
                </h5>
              </div>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ElectricalVehicleSolution;
