import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createRegisterRequest, fetchCities } from "../axios/index";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../functions/toast";
import background from "../assets/images/background.jpg";

const Register = () => {
  const { isFetching: isFetchingCities, data: cities } = useQuery(
    ["cities"],
    () => fetchCities().then((res) => res.data)
  );

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    companySector: "",
    location: "",
  });

  const navigate = useNavigate();
  const registerMutation = useMutation(
    (formData) => createRegisterRequest(formData),
    {
      onSuccess: () => {
        successToast(
          "Kayıt olma talebiniz tarafımıza ulaşmıştır, en kısa sürede geri dönüş yapılacaktır."
        );
      },
      onError: (error) => {
        if (error?.response?.data?.message) {
          errorToast(error.response.data.message);
        } else {
          errorToast("Bir hata oluştu, lütfen yönetici ile iletişime geçin.");
        }
      },
    }
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.location.length > 0) {
      await registerMutation.mutateAsync(formData);
      navigate("/");
    } else {
      errorToast("Devam etmek için bir şehir seçiniz.");
    }
  };

  useEffect(() => {}, [formData]);

  return (
    <div
      className="color-overlay d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {cities?.length > 0 && (
        <Form
          onSubmit={onSubmit}
          className="form-signin w-100 m-auto rounded p-5 p-sm-4 w-25 "
        >
          <div className="h2 ">
            <Form.Text style={{ color: "#097969", fontWeight: 600 }}>
              Kayıt Ol
            </Form.Text>
          </div>
          <Form.Group className="mb-3 h5 " controlId="formFullName">
            <Form.Label className="d-flex justify-content-start">
              İsim Soyisim
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setFormData({ ...formData, fullName: e.target.value });
              }}
              //type="email"
              placeholder="İsim Soyisim"
              maxLength="512"
              minLength="2"
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>

          <Form.Group className="mb-3 h5" controlId="formEmail">
            <Form.Label className="d-flex justify-content-start">
              E-Mail
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
              placeholder="E-Mail adresi"
            />
          </Form.Group>
          <Form.Group className="mb-3 h5" controlId="formPhoneNumber">
            <Form.Label className="d-flex justify-content-start">
              Telefon
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setFormData({ ...formData, phoneNumber: e.target.value });
              }}
              type="number"
              placeholder="Telefon Numarası"
            />
          </Form.Group>
          <Form.Group className="mb-3 h5" controlId="formLocation">
            <Form.Label className="d-flex justify-content-start">
              Şehir
            </Form.Label>
            <Form.Select
              required
              value={formData.location}
              onChange={(e) => {
                setFormData({ ...formData, location: e.target.value });
              }}
            >
              <option selected value>
                Bir şehir seçin
              </option>
              {cities?.map((city) => (
                <option value={city._id}>{city.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 h5" controlId="formCompanyName">
            <Form.Label className="d-flex justify-content-start">
              Şirket Adı
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setFormData({ ...formData, companyName: e.target.value });
              }}
              placeholder="Şirket Adı"
            />
          </Form.Group>
          <Form.Group className="mb-3 h5" controlId="formCompanySector">
            <Form.Label className="d-flex justify-content-start">
              Şirket Sektörü
            </Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setFormData({ ...formData, companySector: e.target.value });
              }}
              placeholder="Şirket Sektörü"
            />
          </Form.Group>
          <Button
            className="w-100 shadow"
            style={{ backgroundColor: "#097969" }}
            type="submit"
          >
            Kayıt Ol
          </Button>
          <Button
            onClick={() => navigate("/")}
            className="w-100 shadow mt-2"
            style={{
              backgroundColor: "#50C878",
              color: "#097969",
              fontWeight: 500,
            }}
            type="submit"
          >
            Geri Dön
          </Button>
        </Form>
      )}
    </div>
  );
};

export default Register;
