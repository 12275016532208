import logo from "./logo.svg";
import "./App.css";
import "./css/custom.css";
import Login from "./Components/Login";
import MainPage from "./Pages/MainPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AdminMainPage from "./Pages/AdminMainPage";
import AdminUserListPage from "./Pages/AdminUserListPage";
import UserPage from "./Pages/UserPage";
import Register from "./Components/Register";
import UserNewData from "./Pages/UserNewData";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MembershipPage from "./Pages/MembershipPage";
import AdminUsersPage from "./Pages/AdminUsersPage";
import ContactPage from "./Pages/ContactPage";
import AboutPage from "./Pages/AboutPage";
import UserDetailsPage from "./Pages/UserDetailsPage";
import ExCalc from "./Pages/ExCalc";
import SolutionPage from "./Pages/SolutionPage";
import FuelPage from "./Pages/FuelPage";
import PasswordPage from "./Pages/PasswordPage";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<MainPage />} />
            <Route path="/membership" element={<MembershipPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/admin" element={<AdminMainPage />} />
            <Route path="/adminUsers" element={<AdminUsersPage />} />
            <Route path="/adminUserList" element={<AdminUserListPage />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/newData" element={<UserNewData />} />
            <Route path="/userDetails" element={<UserDetailsPage />} />
            <Route path="/exCalc" element={<ExCalc />} />
            <Route path="/solution" element={<SolutionPage />} />
            <Route path="/fuel" element={<FuelPage />} />
            <Route path="/password" element={<PasswordPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
