import React from "react";
import SideBar from "../Components/SideBar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCalculations,
  fetchUsersRequest,
  deleteUserRequest,
  reactivateUserRequest,
} from "../axios/index";
import { ButtonGroup, Table, Button, Modal, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TopBar from "../Components/TopBar";
import { useState } from "react";
import { errorToast, successToast } from "../functions/toast";

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useQuery(["usersRequests"], () =>
    fetchUsersRequest().then((res) => res.data)
  );

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <TopBar />
      <div class="container-fluid">
        <div class="row">
          <SideBar />
          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
              <h1 class="h2">Kullanıcılar</h1>
            </div>
            <div class="row col-md-12">
              <div class="table-responsive mt-5">
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th>İsim Soyisim</th>
                      <th>Email</th>
                      <th>Telefon</th>
                      <th>Şirket Adı</th>
                      <th>Şirket Sektörü</th>
                      <th>Üyelik Başlangıç Tarihi</th>
                      <th>İşlemler</th>
                    </tr>
                  </thead>
                  {data &&
                    data.map((userRequest) => {
                      if (userRequest.isAdmin) {
                        return null;
                      }
                      return (
                        <tbody>
                          <tr>
                            <td key={userRequest.fullName}>
                              {userRequest.fullName}
                            </td>
                            <td key={userRequest.email}>{userRequest.email}</td>
                            <td key={userRequest.phoneNumber}>
                              {userRequest.phoneNumber}
                            </td>
                            <td key={userRequest.companyName}>
                              {userRequest.companyName}
                            </td>
                            <td key={userRequest.companySector}>
                              {userRequest.companySector}
                            </td>
                            {userRequest.membershipStartingDate && (
                              <td key={userRequest.membershipStartingDate}>
                                {userRequest.membershipStartingDate
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </td>
                            )}
                            <td>
                              <Button
                                size="sm"
                                variant="info"
                                style={{ marginRight: "5px" }}
                                onClick={async () => {
                                  //handleShow();

                                  const response = await fetchCalculations(
                                    userRequest._id
                                  );
                                  navigate("/userDetails", {
                                    state: {
                                      user: userRequest,
                                      calculations: response.data,
                                    },
                                  });
                                }}
                              >
                                Detay
                              </Button>
                              {show && (
                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  size="xxl"
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Modal heading</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Accordion>
                                      {data &&
                                        data.map((response) => {
                                          return (
                                            <div>
                                              <Accordion.Item
                                                eventKey={response._id}
                                                className="my-2"
                                              >
                                                <Accordion.Header>
                                                  <div>
                                                    <h5>{data.name}</h5>
                                                    <h5>{data.value}</h5>
                                                  </div>
                                                </Accordion.Header>
                                              </Accordion.Item>
                                            </div>
                                          );
                                        })}
                                    </Accordion>
                                  </Modal.Body>
                                </Modal>
                              )}
                              {userRequest.isActive ? (
                                <Button
                                  size="sm"
                                  variant="danger"
                                  style={{ marginRight: "5px" }}
                                  onClick={async () => {
                                    const response = await deleteUserRequest(
                                      userRequest._id
                                    );

                                    if (response.status === 200) {
                                      successToast(
                                        "Kullanıcı hesabı donduruldu."
                                      );
                                      await queryClient.invalidateQueries([
                                        "usersRequests",
                                      ]);
                                    } else {
                                      errorToast(
                                        "Bir hata oluştu tekrar deneyin."
                                      );
                                    }
                                  }}
                                >
                                  Hesabı Askıya Al
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="success"
                                  style={{ marginRight: "5px" }}
                                  onClick={async () => {
                                    const response =
                                      await reactivateUserRequest(
                                        userRequest._id
                                      );

                                    if (response.status === 200) {
                                      successToast(
                                        "Kullanıcı hesabı 1 yıllığına aktif hale getirildi."
                                      );
                                      await queryClient.invalidateQueries([
                                        "usersRequests",
                                      ]);
                                    } else {
                                      errorToast(
                                        "Bir hata oluştu tekrar deneyin."
                                      );
                                    }
                                  }}
                                >
                                  Hesabı Aktif Et
                                </Button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AdminUsersPage;
