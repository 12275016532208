const validateEmail = ({ email, setEmailError }) => {
  const emailRegular =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !email.match(emailRegular)
    ? setEmailError("Email not valid")
    : setEmailError("");
};

const validateName = ({ name, setNameError }) => {
  return name && name.length < 3
    ? setNameError("Name is too short")
    : name && name.length > 50
    ? setNameError("Try to make short and meanfull")
    : setNameError("");
};

const validateSurname = ({ surname, setSurnameError }) => {
  return surname && surname.length < 3
    ? setSurnameError("Surname is too short")
    : surname && surname.length > 50
    ? setSurnameError("Try to make short and meanfull")
    : setSurnameError("");
};

const validateMessage = ({ message, setMessageError }) => {
  return message && message.length < 5
    ? setMessageError("Message is too short")
    : message && message.length > 200
    ? setMessageError("Try to make short and meanfull")
    : setMessageError("");
};

const validateSubject = ({ subject, setSubjectError }) => {
  return subject && subject.length < 3
    ? setSubjectError("Subject is too short")
    : subject && subject.length > 200
    ? setSubjectError("Try to make short and meanfull")
    : setSubjectError("");
};

export {
  validateEmail,
  validateName,
  validateMessage,
  validateSurname,
  validateSubject,
};
