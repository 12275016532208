import React, { useState } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import { Button, Form } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { changePassword } from "../axios";
import { errorToast, successToast } from "../functions/toast";

const PasswordPage = () => {
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const changePasswordMutation = useMutation(
    (payload) => changePassword(payload),
    {
      onSuccess: () => {
        successToast("Şifre başarıyla güncellendi.");
      },
      onError: (error) => {
        errorToast(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Bir hata oluştu, tekrar deneyin."
        );
      },
    }
  );

  const handleChangePassword = async (oldPassword, newPassword) => {
    await changePasswordMutation.mutateAsync({
      oldPassword,
      newPassword,
    });
  };
  return (
    <>
      <TopBar />
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
              <h1 className="h2">Şifreyi Değiştir</h1>
            </div>
            <Form
              className="mt-3"
              onSubmit={(e) => {
                e.preventDefault();
                handleChangePassword(
                  passwords.oldPassword,
                  passwords.newPassword
                );
              }}
            >
              <Form.Control
                type="password"
                placeholder="Mevcut şifreniz"
                value={passwords.oldPassword}
                onChange={(e) => {
                  setPasswords({ ...passwords, oldPassword: e.target.value });
                }}
              />
              <Form.Control
                type="password"
                className="mt-3"
                placeholder="Yeni şifreniz"
                value={passwords.newPassword}
                onChange={(e) => {
                  setPasswords({ ...passwords, newPassword: e.target.value });
                }}
              />
              <Button type="submit" className="my-3" variant="success">
                Güncelle
              </Button>
            </Form>
          </main>
        </div>
      </div>
    </>
  );
};

export default PasswordPage;
