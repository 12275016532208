export const suggestPhotovoltaicPanel = (calculation) => {
  // console.log("calc: ", calculation);

  if (calculation) {
    let electricalFootprint = 0;

    const { calculationItems } = calculation;
    if (calculationItems?.length < 1) return false;

    for (let i = 0; i < calculationItems.length; i++) {
      if (
        calculationItems[i].subcategory.name === "Elektrikli Ürünler" ||
        calculationItems[i].subcategory.name === "Elektrik Tüketimi"
      ) {
        electricalFootprint =
          electricalFootprint +
          calculationItems[i].quantity * calculationItems[i].element.CF;
        console.log("Electrical Footprint value: ", electricalFootprint);
      }
    }

    if (electricalFootprint / calculation.value >= 0.2) {
      return true;
    } else {
      return false;
    }
  }
};

export const suggestRecycledMaterial = (calculation) => {
  // console.log("calc: ", calculation);

  if (calculation) {
    let recycledMaterialFootprint = 0;

    const { calculationItems } = calculation;
    if (calculationItems?.length < 1) return false;

    for (let i = 0; i < calculationItems.length; i++) {
      if (
        calculationItems[i].emission.name ===
        "Malzeme kullanımı kaynaklı dolaylı emisyonlar"
      ) {
        recycledMaterialFootprint =
          recycledMaterialFootprint +
          calculationItems[i].quantity * calculationItems[i].element.CF;
      }
    }

    if (recycledMaterialFootprint / calculation.value >= 0.1) {
      return true;
    } else {
      return false;
    }
  }
};

export const suggestElectricalVehicleSolution = (calculation) => {
  // console.log("calc: ", calculation);

  if (calculation) {
    let electricalVehicleFootprint = 0;

    const { calculationItems } = calculation;
    if (calculationItems?.length < 1) return false;

    for (let i = 0; i < calculationItems.length; i++) {
      if (
        calculationItems[i].emission.name ===
        "Ulaşım Kaynaklı Dolaylı Emisyonlar"
      ) {
        electricalVehicleFootprint =
          electricalVehicleFootprint +
          calculationItems[i].quantity * calculationItems[i].element.CF;
        console.log(
          "Recycled Material Footprint value: ",
          electricalVehicleFootprint
        );
      }
    }

    if (electricalVehicleFootprint / calculation.value >= 0.1) {
      return true;
    } else {
      return false;
    }
  }
};
