import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { me } from "../axios";
import NavBar from "../Components/Nav";
import PhotovoltaicPanelSolution from "../Components/PhotovoltaicPanelSolution";
import RecyledMaterialSolution from "../Components/RecyledMaterialSolution";
import ElectricalVehicleSolution from "../Components/ElectricalVehicleSolution";
import {
  suggestPhotovoltaicPanel,
  suggestRecycledMaterial,
  suggestElectricalVehicleSolution,
} from "../functions/solutions";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import UserTopBar from "../Components/UserTopBar";

const SolutionPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: currentUser } = useQuery(["me"], () =>
    me().then((res) => res.data)
  );

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }

    //
  }, [state, currentUser]);

  return (
    <>
      <UserTopBar />
      <Container className="my-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
          <Button
            size="sm"
            variant="outline-dark"
            style={{ marginRight: "5px" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FeatherIcon
              icon="arrow-left"
              class="icon"
              style={{ marginLeft: -2, marginRight: 1 }}
            />
            Geri Dön
          </Button>
        </div>
        <Row>
          <h4>
            <b>{state?.calculation?.name}</b> adlı hesaplama için çözüm
            önerileri{" "}
          </h4>
          <hr className="border border-2 border-success" />
        </Row>
        <Row>
          {suggestPhotovoltaicPanel(state?.calculation) && (
            <PhotovoltaicPanelSolution
              location={state?.calculation.owner.location}
              calculation={state?.calculation}
            />
          )}
        </Row>
        <Row>
          {suggestRecycledMaterial(state?.calculation) && (
            <RecyledMaterialSolution calculation={state?.calculation} />
          )}
        </Row>
        <Row>
          {suggestElectricalVehicleSolution(state?.calculation) && (
            <ElectricalVehicleSolution calculation={state?.calculation} />
          )}
        </Row>
      </Container>
    </>
  );
};

export default SolutionPage;
