import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { logout, me } from "../axios";
import { useEffect } from "react";
import logo from "../assets/images/LogoWithoutNameTransparentWhite.png";
import logo2 from "../assets/images/logoTextWhiteNoBack.png";

function BasicExample() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isFetching, data } = useQuery(["me"], () =>
    me().then((res) => res.data)
  );

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
        <Container>
          <Navbar.Brand
            style={{
              fontSize: 48,
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Link className="text-decoration-none text-white" to="/">
              <img
                src={logo}
                class="bd-placeholder-img border border-primary"
                width="80"
              />
              <img
                src={logo2}
                class="bd-placeholder-img border border-primary mt-3"
                height="60"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" style={{ fontSize: 24 }}>
                Ana Sayfa
              </Nav.Link>
              <Nav.Link href="/about" style={{ fontSize: 24 }}>
                Hakkımızda
              </Nav.Link>
              <Nav.Link href="/membership" style={{ fontSize: 24 }}>
                Üyelik
              </Nav.Link>
              <Nav.Link href="/contact" style={{ fontSize: 24 }}>
                İletişim
              </Nav.Link>
            </Nav>
            <Nav
              className="d-flex justify-content-center align-items-center"
              style={{ fontSize: 24 }}
            >
              {/* <NavDropdown
                title="TR"
                id="collasible-nav-dropdown"
                style={{ fontSize: 24, marginRight: 5 }}
              >
                <NavDropdown.Item>TR</NavDropdown.Item>
                <NavDropdown.Item>EN</NavDropdown.Item>
              </NavDropdown> */}
              <div class="btn-group">
                {data ? (
                  <Link
                    onClick={async (e) => {
                      await logout();
                      queryClient.setQueryData(["me"], null);
                      navigate("/");
                    }}
                    className="text-decoration-none text-white btn btn-danger ms-2 mt-2"
                    style={{ fontSize: 16 }}
                    id="loginLogoutButton"
                  >
                    Çıkış yap
                  </Link>
                ) : (
                  <Link
                    className="text-decoration-none text-white btn btn-dark ms-2 mt-2"
                    style={{ fontSize: 16, paddingTop: 8 }}
                    to="/login"
                    id="loginLogoutButton"
                  >
                    Giriş Yap
                  </Link>
                )}
                {!data && (
                  <Nav.Link
                    className="text-decoration-none text-white btn btn-dark ms-2 mt-2"
                    id="registerButton"
                    href="/register"
                    style={{ fontSize: 16 }}
                  >
                    Kayıt Ol
                  </Nav.Link>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default BasicExample;
