import React from "react";
//import SidebarMenu from "react-bootstrap-sidebar-menu";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import AdminMainPage from "../Pages/AdminUserListPage";
import AdminUserListPage from "../Pages/AdminUserListPage";
import { logout, me } from "../axios";
import { useQueryClient } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";

const SideBar = () => {
  // const queryClient = useQueryClient();
  return (
    <>
      <nav
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-md-block sidebar collapse"
        style={{ backgroundColor: "#191c1f" }}
      >
        <div class="position-sticky pt-3 sidebar-sticky">
          <ul
            class="nav d-flex mx-auto flex-column"
            style={{ alignItems: "flex-start" }}
          >
            <li class="nav-item">
              <div class="sidebar-item text-white">
                <Link className="text-decoration-none" to="/admin">
                  <FeatherIcon
                    icon="home"
                    class="icon"
                    style={{ marginLeft: -2, marginRight: 1 }}
                  />
                  <span class="text-white">Anasayfa</span>
                </Link>
              </div>
            </li>
            <li class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="user-plus" class="icon" />
                <Link className="text-decoration-none" to="/adminUserList">
                  <span class="text-white">Kullanıcı İstekleri</span>
                </Link>
              </div>
            </li>
            <li class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="users" class="icon" />
                <Link className="text-decoration-none" to="/adminUsers">
                  <span class="text-white">Kullanıcılar</span>
                </Link>
              </div>
            </li>
            <li class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="truck" class="icon" />
                <Link className="text-decoration-none" to="/fuel">
                  <span class="text-white">Yakıt Fiyatları</span>
                </Link>
              </div>
            </li>
            <li class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="key" class="icon" />
                <Link className="text-decoration-none" to="/password">
                  <span class="text-white">Şifre Değiştir</span>
                </Link>
              </div>
            </li>
            <hr />
            <li id="sidebar-exit-button" class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="user-plus" class="icon" />
                <Link className="text-decoration-none" to="/adminUserList">
                  <span class="text-white">Çıkış Yap</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideBar;
