import React, { useEffect } from "react";
import NavBar from "../Components/Nav";
import Footer from "../Components/Footer";
import Button from "react-bootstrap/Button";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useState } from "react";
import {
  validateName,
  validateSurname,
  validateEmail,
  validateSubject,
  validateMessage,
} from "../functions/validation";
import { errorToast, successToast } from "../functions/toast";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");

  const containerStyle = {
    width: window.innerWidth > 768 ? "500px" : "100%",
    height: "250px",
  };

  const center = {
    lat: 38.187900759444865,
    lng: 27.200659712069548,
  };

  useEffect(() => {
    validateName({ name, setNameError });
    validateSurname({ surname, setSurnameError });
    validateEmail({ email, setEmailError });
    validateSubject({ subject, setSubjectError });
    validateMessage({ message, setMessageError });
  }, [name, surname, email, subject, message]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://api.erasust.web.tr/users/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          surname: surname,
          email: email,
          subject: subject,
          message: message,
        }),
      });
      let data = await res.json();

      if (res.status === 200) {
        successToast(data.message);
      } else {
        errorToast(data.message);
      }

      setName("");
      setSurname("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <NavBar />
      <div class="container-fluid mb-5">
        <div class="container">
          <div>
            <h2 class="mt-3 shadow-sm p-3 border border-primary">
              Bize Ulaşın
            </h2>
            <p class="mb-5">
              Firmanıza, süreçlerinize ve ürünlerinize özel oluşturulacak paket
              tekliflerimizle ilgili bilgi almak için bize aşağıdaki kanallardan
              erişebilirsiniz:
            </p>
          </div>
          <form onSubmit={submitHandler}>
            <div class="row border border-primary">
              <div class="col-md-7 shadow rounded p-5">
                <div class="row">
                  <div class="col mb-3">
                    <label class="form-label text-black d-flex justify-content-flex-end mx-1">
                      Adınız
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="Adınız"
                      value={name}
                    />
                  </div>
                  <div class="col mb-3">
                    <label class="form-label text-black d-flex justify-content-flex-end mx-1">
                      Soyadınız
                    </label>
                    <input
                      onChange={(e) => setSurname(e.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="Soyadınız"
                      value={surname}
                    />
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label text-black d-flex justify-content-flex-end mx-1">
                    Email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    class="form-control"
                    placeholder="E-mail adresiniz"
                    value={email}
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-black d-flex justify-content-flex-end mx-1">
                    Konu
                  </label>
                  <input
                    onChange={(e) => setSubject(e.target.value)}
                    type="text"
                    class="form-control"
                    placeholder="Konu"
                    value={subject}
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label text-black d-flex justify-content-flex-end mx-1">
                    Mesaj
                  </label>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    name=""
                    class="form-control"
                    placeholder="Mesajınızı buraya yazınız "
                    value={message}
                  ></textarea>
                </div>
                <div class="mb-3 d-flex" style={{ justifyContent: "flex-end" }}>
                  <Button variant="success" type="submit">
                    Mesajı Gönder
                  </Button>
                </div>
              </div>
              <div class="col-md-5 bg-primary">
                <div class="ml-5">
                  <h2 class="mt-3 text-white">İletişim Bilgileri</h2>
                  <p class="mt-1 text-white fs-5 font-weight-lighter">
                    CANSET YAZILIM Çevre Enerji Danışmanlık Hizmetleri Ticaret
                    A.Ş.
                  </p>
                  <p class="text-white fs-5 font-weight-lighter">
                    İTOB OSB Mahallesi 10032. Sokak No: 2 Menderes / İZMİR
                  </p>
                  <div class="d-flex justify-content-center">
                    <iframe
                      width={425}
                      height={350}
                      src="https://www.openstreetmap.org/export/embed.html?bbox=27.19788104295731%2C38.186717764921504%2C27.20236033201218%2C38.188560323192185&amp;layer=mapnik&amp;marker=38.18763904988282%2C27.20012068748474"
                      style={{ border: "1px solid black" }}
                    ></iframe>
                    <br />
                    <small>
                      <a
                        href="https://www.openstreetmap.org/?mlat=38.18764&amp;mlon=27.20012#map=18/38.18764/27.20012"
                        target="blank"
                      >
                        View Larger Map
                      </a>
                    </small>
                  </div>

                  <p class="text-white fs-5 font-weight-lighter">
                    E-posta: info@can-set.com
                  </p>
                  <p class="text-white fs-5 font-weight-lighter">
                    Telefon: +90 533 427 15 39
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="absolute">
        <Footer />
      </div>
    </>
  );
};

export default ContactPage;
