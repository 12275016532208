import React, { useEffect, useState } from "react";
import UserSideBar from "../Components/UserSideBar";
import UserTopBar from "../Components/UserTopBar";
import { useLocation } from "react-router-dom";
import { Button, Table, Modal, Form, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../functions/toast";
import { NewDataComponent } from "../Components/NewDataComponent";
import { useMutation } from "@tanstack/react-query";
import {
  deleteCalculationRequest,
  deleteCalculationItemRequest,
  getCalculationByUserId,
  updateCalculationItemRequest,
  me,
} from "../axios";

import { useQuery } from "@tanstack/react-query";

const UserDetailsPage = () => {
  const location = useLocation();

  const { isFetching, data } = useQuery(["me"], () =>
    me().then((res) => res.data)
  );

  const navigate = useNavigate();

  const [usersCalculations, setUsersCalculations] = useState(
    location?.state?.initialCalculationsData
  );
  const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  const handleClose = () => setShow();

  const [showNewCalculation, setShowNewCalculation] = useState(false);
  const handleShowNewCalculation = () => setShowNewCalculation(true);
  const handleCloseNewCalculation = () => setShowNewCalculation(false);

  const [showUpdateElement, setShowUpdateElement] = useState();
  const handleShowUpdateElement = () => setShowUpdateElement(true);
  const handleCloseUpdateElement = () => setShowUpdateElement(false);

  const [currentCalculation, setCurrentCalculation] = useState();

  // Bu ikisi kullanılacak mı?
  const [deleteCalculation, setDeleteCalculation] = useState(false);
  const [updateCalculationItem, setUpdateCalculationItem] = useState(false);
  const [updateCalculationItemInput, setUpdateCalculationItemInput] =
    useState();

  const getUsersCalculations = async (id) => {
    const { data } = await getCalculationByUserId(id);
    setUsersCalculations(data);
  };

  useEffect(() => {
    if (!isFetching && !data) {
      navigate("/");
    }
  }, [data, usersCalculations]);

  const newCalculationCallback = async () => {
    setShow();
    getUsersCalculations(data?.user?._id);
  };

  const deleteCalculationMutation = useMutation(
    (payload) => deleteCalculationRequest(payload.id),
    {
      onSuccess: () => {
        successToast("Hesaplama silme işlemi başarılı.");
        getUsersCalculations(data?.user?._id);
        setShow();
        setCurrentCalculation();
      },
      onError: () => {
        errorToast("Hesaplama silme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const deleteCalculationItemMutation = useMutation(
    (payload) =>
      deleteCalculationItemRequest(payload.id, payload.currentCalculationId),
    {
      onSuccess: () => {
        successToast("Hesaplama maddesi silme işlemi başarılı.");
        getUsersCalculations(data?.user?._id);
        setShow();
        setCurrentCalculation();
      },
      onError: () => {
        errorToast("Hesaplama maddesi silme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const updateCalculationItemMutation = useMutation(
    (payload) => updateCalculationItemRequest(payload),
    {
      onSuccess: () => {
        successToast("Miktar düzenleme işlemi başarılı.");
        getUsersCalculations(data?.user?._id);
      },
      onError: () => {
        errorToast("Miktar düzenleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const handleDeleteCalculation = async (id) => {
    await deleteCalculationMutation.mutateAsync({
      id,
      // Bu ne işe yarıyor
      deleteCalculation,
    });

    setDeleteCalculation((deleteCalculation) => !deleteCalculation);
    //Silmek istiyor musun diye sorulacak
  };

  const handleDeleteCalculationItem = async (id, currentCalculationId) => {
    await deleteCalculationItemMutation.mutateAsync({
      id,
      currentCalculationId,
    });
    //Silmek istiyor musun diye sorulacak
  };

  const handleUpdateCalculationItem = async (id, currentCalculationId) => {
    await updateCalculationItemMutation.mutateAsync({
      id,
      updateCalculationItemInput,
      currentCalculationId,
    });
    setShowUpdateElement();
    setShow();
    setCurrentCalculation();
    setUpdateCalculationItem();
  };

  const [chartData, setChartData] = useState({
    labels: ["Enerji", "Ulaşım", "Orman", "Kimyasal", "Ambalaj", "Gıda"],
    datasets: [
      {
        label: "Carbonfootprint Values",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(88, 77, 75, 0.2)",
          "rgba(220, 15, 125, 0.2)",
          "rgba(20, 82, 56, 0.2)",
          "rgba(188, 216, 183, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(88, 77, 75, 0.6)",
          "rgba(220, 15, 125, 0.6)",
          "rgba(20, 82, 56, 0.6)",
          "rgba(188, 216, 183, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  });

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
  );

  var pieChartReference = React.createRef();

  const stackedBarChartoptions = {
    plugins: {
      title: {
        display: true,
        text: "Karbon Ayak İzi",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = usersCalculations.map((calculation) => calculation.name);

  const stackedBarChartData = {
    labels,
    datasets: [
      {
        label: "Karbon Ayak İzi Değeri",
        data: usersCalculations?.map((calculation) => calculation.value),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  return (
    <>
      <UserTopBar />
      <div class="container-fluid">
        <div class="row">
          <UserSideBar />
          {usersCalculations && (
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div class="d-flex justify-content-between flex-wrap flex-row-reverse flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
                <h4 class="h4">{data?.user?.fullName}</h4>
              </div>

              <div class="row col-md-12 mt-3 border border-black rounded d-flex align-items-center justify-content-space-around">
                <div
                  className="chart"
                  class="col-md-5 mb-5 mt-5 mx-5 mh-50"
                  style={{ height: 350 }}
                >
                  <Pie
                    ref={pieChartReference}
                    data={chartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: { position: "top" },
                        title: { display: true, text: "Pie Chart" },
                      },
                    }}
                  />
                </div>
                <div class="col-md-6 mb-5 mt-5 mh-50" style={{ height: 350 }}>
                  <Bar
                    options={stackedBarChartoptions}
                    data={stackedBarChartData}
                  />
                </div>
              </div>
              <div class="row col-md-12 mt-3 n border border-black rounded">
                <div class="btn-toolbar mt-4 mb-md-0 ml-2 flex-row-reverse">
                  <Button
                    size="md"
                    variant="primary"
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      setCurrentCalculation();
                      handleShowNewCalculation();
                    }}
                  >
                    <FeatherIcon
                      icon="plus-square"
                      class="icon"
                      style={{ marginLeft: -2, marginRight: 1 }}
                    />
                    Yeni Hesaplama Ekle
                  </Button>
                  {showNewCalculation && (
                    <Modal
                      show={showNewCalculation}
                      onHide={handleCloseNewCalculation}
                      size="xl"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {currentCalculation
                            ? "Yeni Madde Ekle"
                            : "Yeni Hesaplama Ekle"}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <NewDataComponent
                          owner={data?.user?._id}
                          newCalculationCallback={newCalculationCallback}
                          currentCalculation={
                            currentCalculation ? currentCalculation : null
                          }
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseNewCalculation}
                        >
                          Kapat
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
                <div class=" row table-responsive mt-4">
                  <Table responsive hover size="sm">
                    <thead>
                      <tr>
                        <th>Hesaplama Adı</th>
                        <th>Karbon Ayak İzi Değeri (kg CO₂)</th>
                        <th>İşlemler</th>
                        <th>Çözüm Önerileri</th>
                      </tr>
                    </thead>
                    {usersCalculations.map((calculation) => {
                      return (
                        <tbody key={calculation._id}>
                          <tr>
                            <td>{calculation.name}</td>
                            <td>{Number(calculation.value.toFixed(3))}</td>
                            <td>
                              <Button
                                size="sm"
                                variant="dark"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  setCurrentCalculation(calculation);
                                  setChartData({
                                    labels: calculation.calculationItems.map(
                                      (response) => response.element.name
                                    ),
                                    datasets: [
                                      {
                                        label:
                                          "karbon ayak izi değeri (kg CO₂)",
                                        data: calculation.calculationItems.map(
                                          (response) =>
                                            response?.quantity *
                                            response?.element?.CF
                                        ),
                                        backgroundColor: [
                                          "rgba(255, 99, 132, 0.2)",
                                          "rgba(54, 162, 235, 0.2)",
                                          "rgba(255, 206, 86, 0.2)",
                                          "rgba(75, 192, 192, 0.2)",
                                          "rgba(153, 102, 255, 0.2)",
                                          "rgba(255, 159, 64, 0.2)",
                                        ],
                                        borderColor: [
                                          "rgba(255, 99, 132, 1)",
                                          "rgba(54, 162, 235, 1)",
                                          "rgba(255, 206, 86, 1)",
                                          "rgba(75, 192, 192, 1)",
                                          "rgba(153, 102, 255, 1)",
                                          "rgba(255, 159, 64, 1)",
                                        ],
                                      },
                                    ],
                                  });
                                }}
                              >
                                Grafik
                              </Button>
                              <Button
                                size="sm"
                                variant="info"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  setShow(calculation._id);
                                  setCurrentCalculation(calculation);
                                }}
                              >
                                Detay
                              </Button>
                              {show === calculation._id &&
                                currentCalculation && (
                                  <Modal
                                    show={show}
                                    onHide={handleClose}
                                    size="xl"
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        {currentCalculation.name}
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <Table responsive>
                                        <thead>
                                          <tr>
                                            <th>Madde Adı</th>
                                            <th>Emisyon Türü</th>
                                            <th>Kategori Türü</th>
                                            <th>Yan Kategori Türü</th>
                                            <th>
                                              Karbon Ayak İzi Değeri (kg CO₂)
                                            </th>
                                            <th>Miktar</th>
                                            <th>Birim</th>
                                            <th>İşlemler</th>
                                            <th>
                                              <Button
                                                size="sm"
                                                variant="success"
                                                onClick={
                                                  handleShowNewCalculation
                                                }
                                              >
                                                Yeni Madde Ekle
                                              </Button>
                                            </th>
                                          </tr>
                                        </thead>

                                        {currentCalculation?.calculationItems.map(
                                          (response) => {
                                            return (
                                              <tbody
                                                key={response?.element?._id}
                                              >
                                                <tr>
                                                  <td>
                                                    {response?.element?.name}
                                                  </td>
                                                  <td>
                                                    {response?.emission?.name}
                                                  </td>
                                                  <td>
                                                    {response?.category?.name}
                                                  </td>
                                                  <td>
                                                    {
                                                      response?.subcategory
                                                        ?.name
                                                    }
                                                  </td>
                                                  <td>
                                                    {response?.element?.CF}
                                                  </td>
                                                  <td>{response?.quantity}</td>
                                                  <td>
                                                    {
                                                      response?.element?.symbol
                                                        ?.symbol
                                                    }
                                                  </td>
                                                  <td>
                                                    <Button
                                                      size="sm"
                                                      variant="warning"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowUpdateElement(
                                                          response._id
                                                        );
                                                      }}
                                                    >
                                                      Düzenle
                                                    </Button>
                                                    {showUpdateElement ===
                                                      response._id && (
                                                      <Modal
                                                        show={showUpdateElement}
                                                        onHide={
                                                          handleCloseUpdateElement
                                                        }
                                                        size="xl"
                                                      >
                                                        <Modal.Header
                                                          closeButton
                                                        >
                                                          <Modal.Title>
                                                            Miktarı Güncelle
                                                          </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                          <Form>
                                                            <Form.Group
                                                              className="mb-3"
                                                              controlId="exampleForm.ControlInput1"
                                                            >
                                                              <Form.Label>
                                                                {
                                                                  response
                                                                    ?.emission
                                                                    ?.name
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                  response
                                                                    ?.category
                                                                    ?.name
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                  response
                                                                    ?.subcategory
                                                                    ?.name
                                                                }{" "}
                                                                -{" "}
                                                                {
                                                                  response
                                                                    ?.element
                                                                    ?.name
                                                                }
                                                              </Form.Label>
                                                              <br />
                                                              <Form.Label>
                                                                Miktar
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="number"
                                                                placeholder="Miktar"
                                                                defaultValue={
                                                                  response.quantity
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setUpdateCalculationItemInput(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                                autoFocus
                                                              />
                                                            </Form.Group>
                                                          </Form>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                          <Button
                                                            variant="danger"
                                                            onClick={
                                                              handleCloseUpdateElement
                                                            }
                                                          >
                                                            Kapat
                                                          </Button>
                                                          <Button
                                                            variant="primary"
                                                            onClick={(e) => {
                                                              handleUpdateCalculationItem(
                                                                response._id,
                                                                currentCalculation._id
                                                              );
                                                            }}
                                                          >
                                                            Kaydet
                                                          </Button>
                                                        </Modal.Footer>
                                                      </Modal>
                                                    )}
                                                    <Button
                                                      size="sm"
                                                      variant="danger"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                      onClick={(e) => {
                                                        handleDeleteCalculationItem(
                                                          response._id,
                                                          currentCalculation._id
                                                        );
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      Sil
                                                    </Button>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            );
                                          }
                                        )}
                                      </Table>
                                    </Modal.Body>
                                  </Modal>
                                )}
                              <Button
                                size="sm"
                                variant="danger"
                                style={{ marginRight: "5px" }}
                                onClick={(e) => {
                                  handleDeleteCalculation(calculation._id);
                                  e.stopPropagation();
                                }}
                              >
                                Hesaplamayı Sil
                              </Button>
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="success"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  navigate("/solution", {
                                    state: {
                                      calculation,
                                    },
                                  });
                                }}
                              >
                                Çözüm Önerisi Al
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDetailsPage;
