import React, { useEffect } from "react";
import NavBar from "../Components/Nav";
import Footer from "../Components/Footer";
import Table from "react-bootstrap/Table";
import FeatherIcon from "feather-icons-react";

const MembershipPage = () => {
  return (
    <>
      <NavBar />
      <div class="container-fluid mb-5">
        <div class="container">
          <div>
            <h2 class="mt-3 shadow-sm p-3 border border-primary">Üyelik</h2>
            <p class="mb-5" style={{ fontSize: 18 }}>
              Uygulamamız yıllık abonelik esasına dayanmaktadır. Müşterilerimiz,
              veritabanımızdan süreçlerinde kullandıkları girdileri bulup
              tüketim miktarlarını bizzat girerek sera gazı emisyon değerlerine
              erişebilmektedir. Ayrıca isteyen müşterilerimiz tüketim verilerini
              bizimle paylaştığı takdirde, sistem girişleri tarafımızca da
              yapılabilmektedir. Hizmet paketlerimiz:
            </p>
            <div class="row">
              <div class="col-md-4">
                <div class="card mb-4 shadow-sm">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Terra</h4>
                    <br />
                    <p>1 yıllık uygulama lisansı</p>
                    <br />
                    <ul style={{ textAlign: "left", listStyleType: "none" }}>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        ERA uygulamasına sınırsız erişim
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firma verileri ile anlık analiz imkânı
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firmanıza özel sonuç grafikleri içeren raporlar
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Bu paket dahilinde veri girişi üye tarafından
                        yapılmaktadır
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Süreç geliştirme danışmanlığı ek ücrete tâbidir
                      </li>
                    </ul>
                    <br />
                    <p style={{ fontSize: 24 }}>
                      <b>20.000 TL</b> /ay + KDV
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card mb-4 shadow-sm">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Mare</h4>
                    <br />
                    <p>1 yıllık uygulama lisansı ve veri danışmanlığı</p>
                    <br />
                    <ul style={{ textAlign: "left", listStyleType: "none" }}>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        ERA uygulamasına sınırsız erişim
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        İlgili firma verilerinin tespiti ve veri kalitesi
                        analizi
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Verilerin uygulamaya girişi
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firma verileri ile anlık analiz imkânı
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firmanıza özel sonuç grafikleri içeren raporlar
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Süreç geliştirme danışmanlığı ek ücrete tâbidir
                      </li>
                    </ul>
                    <br />
                    <p style={{ fontSize: 24 }}>
                      <b>25.000 TL</b> /ay + KDV
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card mb-4 shadow-sm">
                  <div class="card-header">
                    <h4 class="my-0 font-weight-normal">Omnia</h4>
                    <br />
                    <p>
                      1 yıllık uygulama lisansı, veri danışmanlığı ve süreç
                      iyileştirme danışmanlığı
                    </p>
                    <ul style={{ textAlign: "left", listStyleType: "none" }}>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        ERA uygulamasına sınırsız erişim
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        İlgili firma verilerinin tespiti ve veri kalitesi
                        analizi
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Verilerin uygulamaya girişi
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firma verileri ile anlık analiz imkânı
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firmanıza özel sonuç grafikleri içeren raporlar
                      </li>
                      <li>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{ marginLeft: "-20px", color: "green" }}
                        />
                        Firmanıza özel, sera gazı salımlarını düşürmeye yönelik
                        süreç geliştirme hizmeti
                      </li>
                    </ul>
                    <p style={{ fontSize: 24 }}>
                      <b>30.000 TL</b> /ay + KDV
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <br />
                <h2>Planların karşılaştırması</h2>
                <Table responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Terra</th>
                      <th>Mare</th>
                      <th>Omnia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ERA uygulamasına sınırsız erişim</td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Firma verileri ile anlık analiz imkânı</td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Firmanıza özel sonuç grafikleri içeren raporlar</td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        İlgili firma verilerinin tespiti ve veri kalitesi
                        analizi
                      </td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Verilerin uygulamaya girişi</td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Süreç geliştirme danışmanlığı</td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Firmanıza özel, sera gazı salımlarını düşürmeye yönelik
                        süreç geliştirme hizmeti
                      </td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="x"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "red",
                          }}
                        />
                      </td>
                      <td>
                        <FeatherIcon
                          icon="check"
                          class="icon"
                          style={{
                            marginLeft: -2,
                            marginRight: 1,
                            color: "green",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute">
        <Footer />
      </div>
    </>
  );
};

export default MembershipPage;
