import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

const options = [
  { label: "Kağıt", value: "Kağıt" },
  { label: "Plastik", value: "Plastik" },
  { label: "Metal", value: "Metal" },
  { label: "Cam", value: "Cam" },
];

const overrideStrings = {
  selectAll: "Hepsini Seç",
  selectSomeItems: "Seçiniz...",
  search: "Ara",
  allItemsAreSelected: "Hepsi Seçildi",
};

const RecyledMaterialSolution = ({ calculation }) => {
  const [boolean, setBoolean] = useState(false);
  const [selected, setSelected] = useState([]);

  const calculateRecycledCf = (name, cf) => {
    if (name === "Kağıt") {
      let newCf = cf * 0.13;
      return newCf;
    }
    if (name === "Plastik") {
      let newCf = cf * 0.4;
      return newCf;
    }
    if (name === "Metal") {
      let newCf = cf * 0.7;
      return newCf;
    }
    if (name === "Cam") {
      let newCf = cf * 0.4;
      return newCf;
    }
    return cf;
  };

  const calculatePercent = (itemCf, recycledCf, calculationCf) => {
    var result = ((itemCf - recycledCf) / calculationCf) * 100;
    return result.toFixed(2);
  };

  return (
    <Card className="bg-light">
      <Card.Header>
        <h3>Geridönüşümlü malzeme kullanımı</h3>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="mb-3 h5">
            <Form.Label className="d-flex justify-content-start text-black">
              Süreçlerinizde geri dönüştürülmüş malzeme kullanıyor musunuz?”
            </Form.Label>
            <div class="btn-group mb-3" role="group">
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => setBoolean(true)}
              >
                Evet
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => setBoolean(false)}
              >
                Hayır
              </button>
            </div>
          </Form.Group>
          {boolean && (
            <Form.Group className="mb-3 h5">
              <Form.Label className="d-flex justify-content-start text-black">
                Geri dönüştürülmüş malzemelerden hangilerini kullanıyorsunuz?
              </Form.Label>
              <div class="mb-2">
                {/* <pre>{JSON.stringify(selected)}</pre> */}
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  overrideStrings={overrideStrings}
                />
              </div>
            </Form.Group>
          )}
          {selected.length === 4 && boolean === true && (
            <div className="d-flex flex-column">
              <div className="d-flex align-content-center">
                <h5 className="text-black">
                  İşletmenizde her türlü girdi için geri dönüştürülmüş
                  malzemeleri tercih ederek doğayı koruduğunuz ve ülke
                  ekonomisine de katkıda bulunduğunuz için sizi tebrik ederiz
                </h5>
              </div>
            </div>
          )}

          {boolean === false ||
            (selected.length < 4 && selected.length > 0 && (
              <div>
                {calculation.calculationItems.map((item) => (
                  <div className="d-flex flex-column">
                    <div className="d-flex align-content-center">
                      <h5 className="text-black" style={{ marginLeft: "25%" }}>
                        {item.element.name} için geri dönüştürülmüş malzeme
                        kullanımı:{" "}
                        {calculateRecycledCf(
                          item.element.name,
                          item.element.CF
                        )}{" "}
                        kgCO2e
                        <br />
                        {calculatePercent(
                          item.element.CF,
                          calculateRecycledCf(
                            item.element.name,
                            item.element.CF
                          ),
                          calculation.value
                        )}{" "}
                        % oranında azaltım sağlanmıştır.
                      </h5>

                      <h5 className="text-black"></h5>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RecyledMaterialSolution;
