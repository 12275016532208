import React from "react";
import SideBar from "../Components/SideBar";
import { Button, Table, Spinner } from "react-bootstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchRegisterRequests,
  approveRequest,
  declineRequest,
} from "../axios/index";
import { errorToast, successToast } from "../functions/toast";
import TopBar from "../Components/TopBar";
import Message from "../Components/Message";

const AdminUserListPage = () => {
  const queryClient = useQueryClient();

  const { isInitialLoading, isFetching, data } = useQuery(
    ["registerRequests"],
    () => fetchRegisterRequests().then((res) => res.data)
  );

  return (
    <>
      <TopBar />

      <div class="container-fluid">
        <div class="row">
          <SideBar />
          {isInitialLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <Spinner size="xl" />
            </div>
          ) : (
            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
                <h1 class="h2">Kullanıcı İstekleri</h1>
              </div>
              <div class="row col-md-12">
                <div class="table-responsive mt-5">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>İsim Soyisim</th>
                        <th>Email</th>
                        <th>Telefon</th>
                        <th>Şirket Adı</th>
                        <th>Şirket Sektörü</th>
                        <th>İşlemler</th>
                      </tr>
                    </thead>
                    {data &&
                      data.map((userRequest) => {
                        return (
                          <tbody>
                            <tr>
                              <td key={userRequest.fullName}>
                                {userRequest.fullName}
                              </td>
                              <td key={userRequest.email}>
                                {userRequest.email}
                              </td>
                              <td key={userRequest.phoneNumber}>
                                {userRequest.phoneNumber}
                              </td>
                              <td key={userRequest.companyName}>
                                {userRequest.companyName}
                              </td>
                              <td key={userRequest.companySector}>
                                {userRequest.companySector}
                              </td>
                              <Button
                                size="sm"
                                variant="outline-success"
                                className="mx-2"
                                onClick={async () => {
                                  const response = await approveRequest(
                                    userRequest._id
                                  );

                                  if (response.status === 201) {
                                    successToast("Kullanıcı hesabı onaylandı.");
                                    await queryClient.invalidateQueries([
                                      "registerRequests",
                                    ]);
                                  } else {
                                    errorToast(
                                      response?.data?.message
                                        ? response.data.message
                                        : "Bir hata oluştu tekrar deneyin."
                                    );
                                  }
                                }}
                              >
                                Onayla
                              </Button>

                              <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={async () => {
                                  const response = await declineRequest(
                                    userRequest._id
                                  );

                                  if (response.status === 200) {
                                    successToast(
                                      "Hesap oluşturma isteği başarıyla silindi."
                                    );
                                    await queryClient.invalidateQueries([
                                      "registerRequests",
                                    ]);
                                  } else {
                                    errorToast(
                                      response?.data?.message
                                        ? response.data.message
                                        : "Bir hata oluştu tekrar deneyin."
                                    );
                                  }
                                }}
                              >
                                Reddet
                              </Button>
                            </tr>
                          </tbody>
                        );
                      })}
                  </Table>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminUserListPage;
