import React from "react";
import FeatherIcon from "feather-icons-react";


const Footer = () => {
  return (
    <footer class="container">
      <p class="float-end"><a href="#"><FeatherIcon icon="arrow-up" class="icon" /></a></p>
      <p>&copy; 2023 CANSET Yazılım Çevre Enerji Danışmanlık Hizmetleri Ticaret A.Ş.</p>
    </footer>
  );
};

export default Footer;
