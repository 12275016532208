import axios from "axios";

const API = axios.create({
  baseURL: "https://api.erasust.web.tr",
  // baseURL: "http://localhost:5000",
  withCredentials: true,
});

export const login = async (formData) =>
  await API.post("/users/login", formData);

export const me = async () => await API.get("/users/me");

export const logout = async () => await API.get("/users/logout");

export const fetchRegisterRequests = async () =>
  await API.get("/users/register/request");

export const fetchCalculations = async (parameters) =>
  await API.get(`/calculation/getByUserId/${parameters}`);

export const approveRequest = async (requestId) =>
  await API.get(`/users/register/approve/${requestId}`);

export const declineRequest = async (requestId) =>
  await API.delete(`/users/register/decline/${requestId}`);

export const deleteUserRequest = async (userId) =>
  await API.delete(`/users/delete/${userId}`);

export const reactivateUserRequest = async (userId) =>
  await API.get(`/users/reactivate/${userId}`);

export const deleteCalculationRequest = async (calculationId) =>
  await API.delete(`/calculation/calculation-delete/${calculationId}`);

export const deleteCalculationItemRequest = async (
  calculationItemId,
  calculationId
) => {
  return await API.put(
    `/calculation/calculation-item-delete/${calculationItemId}`,
    {
      calculationId,
    }
  );
};

export const createCategory = async (parentEmissionId, categoryName) =>
  await API.post("/elements/category", {
    name: categoryName,
    parentEmissionId,
  });

export const createSubCategory = async (parentCategoryId, name) =>
  await API.post("/elements/subcategory", {
    name,
    parentCategoryId,
  });

export const createElement = async (
  parentSubCategoryId,
  elementName,
  cfValue,
  symbol
) =>
  await API.post("/elements/element", {
    name: elementName,
    CF: cfValue,
    symbol,
    parentSubCategoryId,
  });

export const fetchElements = async () => await API.get("/elements/all");

export const fetchSymbols = async () => await API.get("/elements/symbols");

export const fetchUsers = async () => await API.get("/users/all");

export const createRegisterRequest = async (formData) =>
  await API.post("/users/register/request", formData);

export const sendEmail = async (user) => await API.post(user);

export const updateCategoryRequest = async (parameters) => {
  return await API.put(`/elements/category/${parameters.id}`, {
    name: parameters.updateCategoryInput,
  });
};

export const updateSubcategoryRequest = async (parameters) => {
  return await API.put(`/elements/sub/${parameters.id}`, {
    name: parameters.updateSubcategoryInput,
  });
};

export const updateElementRequest = async (parameters) => {
  return await API.put(`/elements/element/${parameters.id}`, {
    name: parameters.updateElementInput,
    CF: parameters.updateElementCfValue,
    symbol: parameters.updateElementSymbol,
  });
};

export const updateCalculationItemRequest = async (parameters) => {
  return await API.put(`/calculation/calculationItems/${parameters.id}`, {
    quantity: parameters.updateCalculationItemInput,
    calculationId: parameters.currentCalculationId,
  });
};

export const deleteCategoryRequest = async (parameters) => {
  return await API.put(`/elements/category-delete/${parameters.id}`, {
    isActive: parameters.deleteCategory,
  });
};

export const deleteSubcategoryRequest = async (parameters) => {
  return await API.put(`/elements/sub-delete/${parameters.id}`, {
    isActive: parameters.deleteSubcategory,
  });
};

export const deleteElementRequest = async (parameters) => {
  return await API.put(`/elements/element-delete/${parameters.id}`, {
    isActive: parameters.deleteElement,
  });
};
export const postCalculation = async (payload) => {
  return await API.post("/calculation", {
    name: payload.calculationName,
    value: payload.value,
    calculationItems: payload.calculationItems,
    owner: payload?.owner,
  });
};

export const addElements = async (parameters) => {
  return await API.put(`/calculation/${parameters.id}`, {
    value: parameters.value,
    calculationItems: parameters.calculationItems,
  });
};

export const getCalculationByUserId = async (id) =>
  await API.get(`/calculation/getByUserId/${id}`);

export const updateEmissionRequest = async (parameters) => {
  return await API.put(`/elements/emission/${parameters.id}`, {
    name: parameters.updateEmissionInput,
  });
};

export const createEmission = async (emissionName) =>
  await API.post("/elements/emission", { name: emissionName });

export const deleteEmissionRequest = async (parameters) => {
  return await API.put(`/elements/emission-delete/${parameters.id}`, {
    isActive: parameters.deleteEmission,
  });
};

export const fetchUsersRequest = async (formData) =>
  await API.get("/users/all", formData);

export const fetchCities = async () => await API.get("/location/cities");

export const fetchFuelItems = async () => await API.get("/fuel");

export const updateFuelItem = async (parameters) =>
  await API.put(`/fuel/${parameters.id}`, { price: parameters.price });

export const changePassword = async (parameters) =>
  await API.put("/users/changepass", {
    oldPassword: parameters.oldPassword,
    newPassword: parameters.newPassword,
  });
