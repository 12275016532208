import React, { useEffect, useState } from "react";
import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { fetchFuelItems, updateFuelItem } from "../axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "../functions/toast";

const FuelPage = () => {
  const [benzin, setBenzin] = useState(0);
  const [dizel, setDizel] = useState(0);
  const [lpg, setLpg] = useState(0);
  const [sunPanel, setSunPanel] = useState(0);
  const [electric, setElectric] = useState(0);

  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery(["fuelItems"], () =>
    fetchFuelItems().then((res) => res.data)
  );

  const updateFuelItemMutation = useMutation(
    (payload) => updateFuelItem(payload),
    {
      onSuccess: () => {
        successToast("Yakıt bilgisi güncelleme başarılı.");
        queryClient.invalidateQueries(["fuelItems"]);
      },
      onError: () => {
        errorToast("Güncelleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const handleUpdateFuelItem = async (id, price) => {
    await updateFuelItemMutation.mutateAsync({
      id,
      price,
    });
  };

  useEffect(() => {
    data?.forEach((fuelItem) => {
      if (fuelItem.name === "Benzin") {
        setBenzin(fuelItem.price);
      } else if (fuelItem.name === "Dizel") {
        setDizel(fuelItem.price);
      } else if (fuelItem.name === "LPG") {
        setLpg(fuelItem.price);
      } else if (fuelItem.name === "Güneş Paneli") {
        setSunPanel(fuelItem.price);
      } else if (fuelItem.name === "Elektrik") {
        setElectric(fuelItem.price);
      }
    });
  }, [data]);

  return (
    <>
      <TopBar />
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          {isFetching ? (
            <Spinner />
          ) : (
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom">
                <h1 className="h2">Yakıt Fiyatları</h1>
              </div>
              <div className="row col-md-12">
                <div className="table-responsive mt-5">
                  <Table responsive size="sm">
                    <thead>
                      <tr>
                        <th>Yakıt Türü</th>
                        <th>Fiyat (TL)</th>
                      </tr>
                    </thead>
                    {data &&
                      data.map((fuelItem) => (
                        <tbody key={fuelItem._id}>
                          <tr>
                            <td className="w-50">{fuelItem.name}</td>
                            <td>
                              <div className="d-flex">
                                <Form.Control
                                  value={
                                    fuelItem.name === "Benzin"
                                      ? benzin
                                      : fuelItem.name === "Dizel"
                                      ? dizel
                                      : fuelItem.name === "LPG"
                                      ? lpg
                                      : fuelItem.name === "Elektrik"
                                      ? electric
                                      : sunPanel
                                  }
                                  onChange={(e) => {
                                    fuelItem.name === "Benzin"
                                      ? setBenzin(e.target.value)
                                      : fuelItem.name === "Dizel"
                                      ? setDizel(e.target.value)
                                      : fuelItem.name === "LPG"
                                      ? setLpg(e.target.value)
                                      : fuelItem.name === "Elektrik"
                                      ? setElectric(e.target.value)
                                      : setSunPanel(e.target.value);
                                  }}
                                  className="w-75 text-center"
                                />
                                <Button
                                  onClick={(e) => {
                                    handleUpdateFuelItem(
                                      fuelItem._id,
                                      fuelItem.name === "Benzin"
                                        ? benzin
                                        : fuelItem.name === "Dizel"
                                        ? dizel
                                        : fuelItem.name === "LPG"
                                        ? lpg
                                        : fuelItem.name === "Elektrik"
                                        ? electric
                                        : sunPanel
                                    );
                                  }}
                                >
                                  Güncelle
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default FuelPage;
