import React, { useState } from "react";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import logo from "../assets/images/logo.png";
import eraLogo from "../assets/images/eraLogo.png";
import NavBar from "../Components/Nav";
import background from "../assets/images/background.jpg";
import photo1 from "../assets/images/mpPhoto1.jpg";
import photo2 from "../assets/images/mpPhoto2.jpg";
import photo3 from "../assets/images/mpPhoto3.jpg";
import label1 from "../assets/images/label 1.jpg";
import label2 from "../assets/images/label 2.jpg";
import label3 from "../assets/images/label 3.jpg";
import carousel2 from "../assets/images/carousel2.jpg";
import carousel3 from "../assets/images/carousel3.jpg";
import FeatherIcon from "feather-icons-react";
import colorLogo from "../assets/images/LogoWithoutNameTransparentColor.png";

const MainPage = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <NavBar />
      <main>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img className="rounded mx-auto img-fluid img-thumbnail d-block" src={label1} alt="First slide" />
            <Carousel.Caption>
              <h3 class="text-black">Firmanızın kurumsal karbon ayak izini ölçme imkanı</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="rounded mx-auto img-fluid img-thumbnail d-block" src={label2} alt="Second slide" />

            <Carousel.Caption>
              <h3 class="text-black">Süreçlerinizin toplam karbon ayak izi içindeki paylarını tespit edebilme imkanı</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="rounded mx-auto img-thumbnail d-block" src={carousel3} alt="Third slide" />

            <Carousel.Caption>
              <h3 class="text-black">Firmanıza özel karbon ayak izi düşürme stratejileri</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div class="container marketing">
          <div class="row">
            <div class="col-lg-4">
              <img
                src={colorLogo}
                class="bd-placeholder-img rounded-circle border border-primary"
                width="140"
                height="140"
              />
              <h2 class="fw-normal mt-2">Hakkımızda</h2>
              <p>
                Uygulamamız, kurumların sera gazı salımlarını kendi tüketim
                verileri üzerinden anlık olarak takip etmelerini sağlar.
              </p>
              <Link className="btn btn-primary" to="/about">
                Detaylar &raquo;
              </Link>
            </div>
            <div class="col-lg-4">
              <img
                src={colorLogo}
                class="bd-placeholder-img rounded-circle border border-primary"
                width="140"
                height="140"
              ></img>
              <h2 class="fw-normal mt-2">Üyelik</h2>
              <p>
                Müşterilerimiz, veritabanımızdan süreçlerinde kullandıkları
                girdileri bulup tüketim miktarlarını bizzat girerek sera gazı
                emisyon değerlerine erişebilmektedir.
              </p>
              <Link className="btn btn-primary" to="/membership">
                Detaylar &raquo;
              </Link>
            </div>
            <div class="col-lg-4">
              <img
                src={colorLogo}
                class="bd-placeholder-img rounded-circle border border-primary"
                width="140"
                height="140"
              ></img>
              <h2 class="fw-normal mt-2">İletişim</h2>
              <p>
                Firmanıza, süreçlerinize ve ürünlerinize özel oluşturulacak
                paket tekliflerimizle ilgili bilgi almak için bize
                ulaşabilirsiniz.
              </p>
              <Link className="btn btn-primary" to="/contact">
                Detaylar &raquo;
              </Link>
            </div>
          </div>

          <hr class="featurette-divider border border-primary" />

          <div class="row featurette">
            <div class="col-md-7">
              <h2 class="featurette-heading fw-normal lh-1 mt-3">
                Karbon Ayak İzi Nedir ?
              </h2>
              <p class="lead">
                Karbon ayak izi, bir kişi veya organizasyonun sera gazı
                emisyonlarının miktarını ölçmek için kullanılan bir ölçüttür.
                Genellikle karbon dioksit (CO2) eşdeğerinde ifade edilir ve
                enerji kullanımı, ulaşım, üretim, atık yönetimi gibi
                faaliyetlerin neden olduğu sera gazı emisyonlarını kapsar.
                Karbon ayak izi hesaplaması, sera gazı emisyonlarının
                kaynaklarına ve türlerine göre farklı şekillerde
                gerçekleştirilir. Kapsam 1 karbon ayak izi, bir organizasyonun
                doğrudan sera gazı emisyonlarını (örneğin, araç kullanımı veya
                doğal gaz kullanımı) ölçer. Kapsam 2 karbon ayak izi, bir
                organizasyonun elektrik, ısıtma ve soğutma gibi dolaylı sera
                gazı emisyonlarını ölçer. Kapsam 3 karbon ayak izi ise,
                organizasyonun tedarik zinciri, ürünlerin ömrü boyunca kullanımı
                ve atıkların yönetimi gibi faaliyetlerinden kaynaklanan dolaylı
                sera gazı emisyonlarını ölçer. Kapsam 1 ve 2 karbon ayak izleri,
                bir organizasyonun doğrudan kontrolü altındaki faaliyetlerden
                kaynaklanan emisyonları hesaplar. Ancak Kapsam 3 karbon ayak
                izi, organizasyonun dolaylı olarak kontrolü altında olan
                faaliyetlerden kaynaklanan emisyonları hesaplar ve genellikle
                daha geniş bir etki alanını kapsar.
              </p>
            </div>
            <div class="col-md-5 mt-5">
              <img
                class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                src={photo1}
                width={500}
                height={400}
              ></img>
            </div>
          </div>

          <hr class="featurette-divider border border-primary" />

          <div class="row featurette">
            <div class="col-md-7 order-md-2">
              <h2 class="featurette-heading fw-normal lh-1 mt-3">
                Karbon Ayak İzini Neden Hesaplamalıyız ?
              </h2>
              <p class="lead ">
                Şirketler, doğal kaynakları tüketirken sera gazı emisyonlarını
                da artırırlar ve bu da küresel iklim değişikliği gibi çevresel
                sorunlara katkıda bulunur. Bu nedenle, şirketlerin karbon ayak
                izi hesaplama, sera gazı emisyonlarının azaltılması için
                atılabilecek adımların belirlenmesi için bir başlangıç noktası
                sağlar. Şirketler için karbon ayak izi hesaplamanın önemi
                aşağıdaki şekillerde özetlenebilir:
              </p>
              <ul class="lead">
                <li>
                  <b>Yenilenebilir enerji kullanımı:</b>Karbon ayak izi
                  hesaplaması, bir şirketin enerji tüketimini ve sera gazı
                  emisyonlarını belirlemeye yardımcı olur. Bu bilgi, şirketin
                  yenilenebilir enerji kaynaklarına geçiş yapmasına yardımcı
                  olabilir.
                </li>
                <li>
                  <b>Çevre dostu ürünlerin tercih edilmesi:</b>Karbon ayak izi
                  hesaplama, şirketlerin ürünlerinin çevreye olan etkisini
                  belirlemelerine yardımcı olur. Bu, çevre dostu ürünlerin
                  tasarımına, üretimine ve pazarlanmasına daha fazla önem
                  verilmesine yardımcı olabilir.
                </li>
                <li>
                  <b>Rekabet avantajı sağlama:</b>Şirketler, çevresel
                  sürdürülebilirlik konusundaki taahhütlerini ve karbon ayak izi
                  azaltma hedeflerini açıkça belirterek, müşterileri ve
                  yatırımcıları tarafından tercih edilebilirler. Bu, şirketlerin
                  rekabet avantajı elde etmelerine yardımcı olabilir.
                </li>
                <li>
                  <b>İşletme maliyetlerinin düşürülmesi:</b>Karbon ayak izi
                  hesaplaması, enerji ve kaynak tüketimini izlemeye yardımcı
                  olduğundan, şirketler işletme maliyetlerini azaltarak daha
                  verimli hale gelebilirler.
                </li>
              </ul>
              <p class="lead">
                Sonuç olarak, karbon ayak izi hesaplama, bir şirketin çevreye
                olan etkisini ölçmek ve yönetmek için önemli bir araçtır.
                Şirketler, bu hesaplamayı kullanarak, çevresel sürdürülebilirlik
                konusunda taahhütlerini ve hedeflerini açıklayabilirler ve
                gelecekteki çevresel sınırlamaların öngörülmesine yardımcı
                olabilirler.
              </p>
            </div>
            <div class="col-md-5 order-md-1 mt-5">
              <img
                class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                src={photo2}
                width={500}
                height={400}
              ></img>
            </div>
          </div>

          <hr class="featurette-divider border border-primary" />

          <div class="row featurette">
            <div class="col-md-7">
              <h2 class="featurette-heading fw-normal lh-1 mt-3">
                ERA uygulaması size neler sunuyor?
              </h2>
              <p class="lead">
                ERA, kullanıcılarına kendi verileri üzerinden sera gazı salım
                miktarlarını ve karbon ayak izlerini hesaplama imkânı sağlar ve
                bu veriler ışığında firmaya özel çözüm önerileri geliştirir.
                Ayrıca sonuçları kolayca anlaşılabilir görseller halinde
                kullanıcılarına sunar. ERA’nın başlıca özellikleri şu
                şekildedir:
              </p>
              <ul class="lead">
                <li>
                  <b>Kapsamlı Veri Tabanı:</b>Kullanıcılardan alınacak verilerin
                  kapsamlı olması önem arz etmektedir. ERA veritabanındaki
                  veriler enerji kullanımı, atık üretimi gibi alanlarda karbon
                  ayak izi hesaplamaları yapılabilmesini sağlar.
                </li>
                <li>
                  <b>Kişiselleştirme:</b>ERA, firmaların tüketim verilerine
                  uygun olarak ayarlanabilen bir algoritma ile çalışmaktadır.
                  Bu, kullanıcıların daha doğru sonuçlar elde etmelerini sağlar.
                </li>
                <li>
                  <b>Kolay Kullanım:</b>Kullanımı kolay bir arayüze sahip
                  olması, uygulamanın daha geniş bir kullanıcı kitlesine
                  ulaşmasını sağlar. Kullanıcıların kolaylıkla veri
                  girebilmeleri, sonuçları görebilmeleri ve takip edebilmeleri
                  mümkündür.
                </li>
                <li>
                  <b>Düzenli Güncelleme:</b>Uygulamanın düzenli olarak
                  güncellenmesi, kullanıcıların güncel verilerle çalışmalarına
                  olanak tanır. Bu, hesaplamaların doğru sonuçlar vermesi
                  açısından önemlidir.
                </li>
                <li>
                  <b>Geri Bildirimler:</b>Uygulamanın, kullanıcılara geri
                  bildirimler sağlayarak daha iyi kararlar almalarına yardımcı
                  olması önemlidir. Bu geri bildirimler, kullanıcılara
                  verimlilik artırıcı öneriler veya benzeri çevre dostu
                  alternatifler sunarak karbon ayak izlerinin azaltılmasına
                  yardımcı olabilir.
                </li>
                <li>
                  <b>Güvenlik:</b>Uygulamanın kullanıcı verilerini koruması ve
                  gizliliğini sağlaması önemlidir. Bu nedenle, güvenlik ve veri
                  gizliliği konusunda yüksek standartlar belirlemek ve kullanıcı
                  verilerini koruyan mekanizmalar sağlamak önemlidir.
                </li>
              </ul>
            </div>
            <div class="col-md-5 mt-5">
              <img
                class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                src={photo3}
                width={500}
                height={400}
              ></img>
            </div>
          </div>

          <hr class="featurette-divider border border-primary" />
        </div>

        <Footer />
      </main>
    </>
  );
};

export default MainPage;
