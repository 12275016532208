import React, { useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { logout, me } from "../axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/images/adminLogo.svg";
import logo2 from "../assets/images/logoTextWhiteNoBack.png";

const TopBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { data } = useQuery(["me"], () => me().then((res) => res.data));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <header
      class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow d-flex align-items-center py-1"
      style={{ backgroundColor: "#191c1f" }}
    >
      <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-4">
        
        <div class="">
          <img src={logo} class="bd-placeholder-img" height={55}/>
          {/* <img src={logo2} class="bd-placeholder-img mt-1 mx-2" height="35"/>
          <b style={{fontSize:35, fontWeight: "lighter"}}>Admin</b> */}
        </div>

      </a>

      <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => {
          if (showMenu) {
            document.getElementById("sidebarMenu").classList.remove("show");
            setShowMenu(!showMenu);
          } else {
            document.getElementById("sidebarMenu").classList.add("show");
            setShowMenu(!showMenu);
          }
        }}
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="d-flex p-2 align-items-center ">
        <div id="topbar-exit-button" class="navbar-nav">
          <div class="nav-item text-nowrap bg-black">
            <a class="nav-link px-3" href="#">
            <Link
                  onClick={async (e) => {
                    await logout();
                    queryClient.setQueryData(["me"], null);
                    navigate("/")
                  }}
                  className="text-decoration-none text-white"
                >
                  Çıkış yap
                </Link>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopBar;
