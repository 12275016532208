import React, { useEffect } from "react";
import SideBar from "../Components/SideBar";
import {
  Accordion,
  Button,
  Table,
  Form,
  Modal,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import {
  createCategory,
  fetchElements,
  createSubCategory,
  createElement,
  fetchSymbols,
  updateCategoryRequest,
  updateSubcategoryRequest,
  updateElementRequest,
  deleteCategoryRequest,
  deleteSubcategoryRequest,
  deleteElementRequest,
  createEmission,
  updateEmissionRequest,
  deleteEmissionRequest,
} from "../axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "../functions/toast";
import TopBar from "../Components/TopBar";
import FeatherIcon from "feather-icons-react";

const AdminMainPage = () => {
  const [newEmissionName, setNewEmissionName] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  // ToDo: inital state should be a single object, it looks too complicated
  const [newElementName, setNewElementName] = useState("");
  const [newElementCfValue, setNewElementCfValue] = useState();
  const [newElementSymbol, setNewElementSymbol] = useState("");

  const [updateEmissionInput, setUpdateEmissionInput] = useState("");
  const [updateCategoryInput, setUpdateCategoryInput] = useState("");
  const [updateSubcategoryInput, setUpdateSubcategoryInput] = useState("");

  const [updateElementInput, setUpdateElementInput] = useState("");
  const [updateElementCfValue, setUpdateElementCfValue] = useState();
  const [updateElementSymbol, setUpdateElementSymbol] = useState("");

  const [deleteEmission, setDeleteEmission] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [deleteSubcategory, setDeleteSubcategory] = useState(false);
  const [deleteElement, setDeleteElement] = useState(false);

  const { isFetching, data } = useQuery(["elements"], () =>
    fetchElements().then((res) => res.data)
  );

  const { data: symbolsData } = useQuery(["symbols"], () =>
    fetchSymbols().then((res) => res.data)
  );

  const createElementMutation = useMutation(
    (parentSubCategoryId, elementName, cfValue, symbol) =>
      createElement(parentSubCategoryId, elementName, cfValue, symbol),
    {
      onSuccess: () => {
        successToast("Madde oluşturma işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Madde oluşturma işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const createSubCategoryMutation = useMutation(
    (parentCategoryId, subCategoryName) =>
      createSubCategory(parentCategoryId, subCategoryName),
    {
      onSuccess: () => {
        successToast("Alt kategori oluşturma işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Alt kategori oluşturma işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const createCategoryMutation = useMutation(
    (parentEmissionId, categoryName) =>
      createCategory(parentEmissionId, categoryName),
    {
      onSuccess: () => {
        successToast("Kategori oluşturma işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Kategori oluşturma işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const createEmissionMutation = useMutation(
    (emissionName) => createEmission(emissionName),
    {
      onSuccess: () => {
        successToast("Emission oluşturma işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Emission oluşturma işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const updateEmissionMutation = useMutation(
    (id, emissionName) => updateEmissionRequest(id, emissionName),
    {
      onSuccess: () => {
        successToast("Emission düzenleme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Emission düzenleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const updateCategoryMutation = useMutation(
    (id, categoryName) => updateCategoryRequest(id, categoryName),
    {
      onSuccess: () => {
        successToast("Kategori düzenleme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Kategori düzenleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const updateSubcategoryMutation = useMutation(
    (id, subcategoryName) => updateSubcategoryRequest(id, subcategoryName),
    {
      onSuccess: () => {
        successToast("Yan kategori düzenleme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Alt kategori düzenleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const updateElementMutation = useMutation(
    (id, elementName, cfValue, symbol) =>
      updateElementRequest(id, elementName, cfValue, symbol),
    {
      onSuccess: () => {
        successToast("Madde düzenleme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Madde düzenleme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const deleteEmissionMutation = useMutation(
    (id) => deleteEmissionRequest(id),
    {
      onSuccess: () => {
        successToast("Emission silme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Emission silme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const deleteCategoryMutation = useMutation(
    (id) => deleteCategoryRequest(id),
    {
      onSuccess: () => {
        successToast("Kategori silme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Kategori silme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const deleteSubcategoryMutation = useMutation(
    (id) => deleteSubcategoryRequest(id),
    {
      onSuccess: () => {
        successToast("Alt kategori silme işlemi başarılı.");
        queryClient.invalidateQueries(["elements"]);
      },
      onError: () => {
        errorToast("Alt kategori silme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const deleteElementMutation = useMutation((id) => deleteElementRequest(id), {
    onSuccess: () => {
      successToast("Madde silme işlemi başarılı.");
      queryClient.invalidateQueries(["elements"]);
    },
    onError: () => {
      errorToast("Madde silme işlemi başarısız, tekrar deneyin.");
    },
  });

  //const [subCategories, setSubCategories] = useState(false);

  const [showEmission, setShowEmission] = useState(false);
  const [showCategory, setShowCategory] = useState();
  const [showEmissionUpdate, setShowEmissionUpdate] = useState();
  const [showCategoryUpdate, setShowCategoryUpdate] = useState();
  const [showSub, setShowSub] = useState();
  const [showSubUpdate, setShowSubUpdate] = useState();
  const [showElement, setShowElement] = useState();
  const [showElementUpdate, setShowElementUpdate] = useState();

  const handleShow = () => setShowEmission(true);

  const handleClose = () => {
    setShowEmission(false);
  };

  // const handleUpdateShow = (id) => {
  //   setShowUpdate(id);
  // };

  const handleEmissionUpdateShow = () => {
    setShowEmissionUpdate(true);
  };

  const handleEmissionUpdateClose = () => {
    setShowEmissionUpdate(false);
  };

  const handleCategoryShow = () => {
    setShowCategory(true);
  };

  const handleCategoryClose = () => {
    setNewCategoryName("");
    setShowCategory();
  };

  const handleCategoryUpdateClose = () => {
    setUpdateCategoryInput("");
    setShowCategoryUpdate();
  };
  const handleSubUpdateShow = () => {
    setShowSubUpdate(true);
  };
  const handleSubUpdateClose = () => {
    setShowSubUpdate(false);
  };
  const handleElementUpdateShow = () => {
    setShowElementUpdate(true);
  };
  const handleElementUpdateClose = () => {
    setShowElementUpdate(false);
  };

  const handleSubClose = () => {
    setShowSub();
  };

  const handleElementClose = () => {
    setShowElement(false);
  };

  const handleCreateElement = async (parentSubCategoryId) => {
    const { data } = await createElementMutation.mutateAsync({
      parentSubCategoryId,
      newElementName,
      newElementCfValue,
      newElementSymbol,
    });

    setNewElementName("");
    setNewElementCfValue();
    setNewElementSymbol("");
    setShowElement();
  };

  const handleCreateSubCategory = async (categoryId) => {
    const { data } = await createSubCategoryMutation.mutateAsync({
      categoryId,
      newSubCategoryName,
    });
    setNewSubCategoryName("");
    setShowSub();
  };

  const handleCreateCategory = async (parentEmissionId) => {
    const { data } = await createCategoryMutation.mutateAsync({
      parentEmissionId,
      newCategoryName,
    });
    setNewCategoryName("");
    setShowCategory();
  };

  const handleCreateEmission = async () => {
    const { data } = await createEmissionMutation.mutateAsync(newEmissionName);
    setNewEmissionName("");
    setShowEmission(false);
  };

  const handleUpdateEmission = async (id) => {
    const { data } = await updateEmissionMutation.mutateAsync({
      id,
      updateEmissionInput,
    });
    setUpdateEmissionInput("");
    setShowEmission();
  };

  const handleUpdateCategory = async (id) => {
    const { data } = await updateCategoryMutation.mutateAsync({
      id,
      updateCategoryInput,
    });
    setUpdateCategoryInput("");
    setShowCategoryUpdate();
  };

  const handleUpdateSubcategory = async (id) => {
    const { data } = await updateSubcategoryMutation.mutateAsync({
      id,
      updateSubcategoryInput,
    });
    setUpdateSubcategoryInput("");
    setShowSubUpdate();
  };

  const handleUpdateElement = async (id) => {
    const { data } = await updateElementMutation.mutateAsync({
      id,
      updateElementInput,
      updateElementCfValue,
      updateElementSymbol,
    });
    setUpdateElementInput("");
    setUpdateElementCfValue();
    setUpdateElementSymbol("");
    setShowElementUpdate();
  };

  const handleDeleteEmission = async (id) => {
    const { data } = await deleteEmissionMutation.mutateAsync({
      id,
      deleteEmission,
    });
    setDeleteEmission((deleteEmission) => !deleteEmission);
    //Silmek istiyor musun diye sorulacak
  };

  const handleDeleteCategory = async (id) => {
    const { data } = await deleteCategoryMutation.mutateAsync({
      id,
      deleteCategory,
    });
    setDeleteCategory((deleteCategory) => !deleteCategory);
    //Silmek istiyor musun diye sorulacak
  };

  const handleDeleteSubcategory = async (id) => {
    const { data } = await deleteSubcategoryMutation.mutateAsync({
      id,
      deleteSubcategory,
    });
    setDeleteSubcategory((deleteSubcategory) => !deleteSubcategory);
    //Silmek istiyor musun diye sorulacak
  };

  const handleDeleteElement = async (id) => {
    const { data } = await deleteElementMutation.mutateAsync({
      id,
      deleteElement,
    });
    setDeleteElement((deleteElement) => !deleteElement);
    //Silmek istiyor musun diye sorulacak
  };

  const queryClient = useQueryClient();

  useEffect(() => {}, [updateElementSymbol]);

  return (
    <>
      <TopBar />
      <div class="container-fluid">
        <div class="row">
          <SideBar />
          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 class="h2">Ana Sayfa</h1>
              <div class="btn-toolbar mb-2 mb-md-0 ml-2 align-items-center">
                <Button variant="success" size="md" onClick={handleShow}>
                  <FeatherIcon icon="plus-square" />
                  Yeni Emisyon Ekle
                </Button>
                {showEmission && (
                  <Modal show={handleShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Yeni Emisyon Ekle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Emisyon Adı</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              setNewEmissionName(e.target.value);
                            }}
                            autoFocus
                          />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose}>
                        Kapat
                      </Button>
                      <Button variant="success" onClick={handleCreateEmission}>
                        Emisyon Ekle
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
            </div>
            {isFetching ? (
              <Spinner size="xl" />
            ) : (
              <div class="container">
                <div class="col-md-12 mt-5">
                  <Accordion alwaysOpen>
                    {data &&
                      data.map((emission, idx) => {
                        return (
                          <div key={emission._id}>
                            <Accordion.Item
                              className="my-2"
                              eventKey={emission._id}
                            >
                              <Accordion.Header>
                                <div className="w-100 d-flex justify-content-between align-items-center">
                                  <div>{emission.name}</div>
                                  <div class="d-flex">
                                    <ButtonGroup>
                                      <Button
                                        type="button"
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowCategory(emission._id);
                                        }}
                                        className="mx-2"
                                      >
                                        Yeni Kategori Ekle
                                      </Button>
                                      {showCategory === emission._id && (
                                        <Modal
                                          show={showCategory}
                                          onHide={handleCategoryClose}
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title>
                                              Yeni Kategori Ekle
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <Form>
                                              <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                              >
                                                <Form.Label>
                                                  Kategori Adı
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  onChange={(e) => {
                                                    setNewCategoryName(
                                                      e.target.value
                                                    );
                                                  }}
                                                  autoFocus
                                                />
                                              </Form.Group>
                                            </Form>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="danger"
                                              onClick={handleCategoryClose}
                                            >
                                              Kapat
                                            </Button>
                                            <Button
                                              variant="success"
                                              onClick={() => {
                                                handleCreateCategory(
                                                  data[idx]._id
                                                );
                                              }}
                                            >
                                              Kategori Ekle
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      )}
                                      <Button
                                        type="button"
                                        variant="warning"
                                        size="sm"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowEmissionUpdate(emission._id);
                                          setUpdateEmissionInput(emission.name);
                                        }}
                                        className="mx-2"
                                      >
                                        Emisyonu Düzenle
                                      </Button>
                                      {showEmissionUpdate === emission._id && (
                                        <Modal
                                          show={showEmissionUpdate}
                                          onHide={handleEmissionUpdateClose}
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title>
                                              Emisyonu Düzenle
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <Form>
                                              <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                              >
                                                <Form.Label>
                                                  Emisyon Adı
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  defaultValue={emission.name}
                                                  onChange={(e) => {
                                                    setUpdateEmissionInput(
                                                      e.target.value
                                                    );
                                                  }}
                                                  autoFocus
                                                />
                                              </Form.Group>
                                            </Form>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              variant="danger"
                                              onClick={
                                                handleEmissionUpdateClose
                                              }
                                            >
                                              Kapat
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                handleUpdateEmission(
                                                  emission._id
                                                );
                                              }}
                                              variant="success"
                                            >
                                              Güncelle
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      )}
                                      <Button
                                        type="button"
                                        variant="danger"
                                        size="sm"
                                        className="mx-2"
                                        onClick={(e) => {
                                          handleDeleteEmission(emission._id);
                                          e.stopPropagation();
                                        }}
                                      >
                                        Emisyonu Sil
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                {emission?.categories?.map((category, idx) => {
                                  return (
                                    <div key={category._id}>
                                      <Accordion.Item eventKey={category._id}>
                                        <Accordion.Header>
                                          <div className="w-100 d-flex justify-content-between align-items-center">
                                            <div>{category.name}</div>
                                            <div class="d-flex">
                                              <ButtonGroup>
                                                <Button
                                                  type="button"
                                                  variant="outline-primary"
                                                  size="sm"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowSub(category._id);
                                                  }}
                                                  className="mx-2"
                                                >
                                                  Yeni Alt Kategori Ekle
                                                </Button>
                                                {showSub === category._id && (
                                                  <Modal
                                                    show={showSub}
                                                    onHide={handleSubClose}
                                                  >
                                                    <Modal.Header closeButton>
                                                      <Modal.Title>
                                                        Yeni Alt Kategori Ekle
                                                      </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                      <Form>
                                                        <Form.Group
                                                          className="mb-3"
                                                          controlId="exampleForm.ControlInput1"
                                                        >
                                                          <Form.Label>
                                                            Kategori Adı
                                                          </Form.Label>
                                                          <Form.Control
                                                            type="text"
                                                            onChange={(e) => {
                                                              setNewSubCategoryName(
                                                                e.target.value
                                                              );
                                                            }}
                                                            autoFocus
                                                          />
                                                        </Form.Group>
                                                      </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button
                                                        variant="danger"
                                                        onClick={handleSubClose}
                                                      >
                                                        Kapat
                                                      </Button>
                                                      <Button
                                                        variant="success"
                                                        onClick={() => {
                                                          handleCreateSubCategory(
                                                            category._id
                                                          );
                                                        }}
                                                      >
                                                        Alt Kategori Ekle
                                                      </Button>
                                                    </Modal.Footer>
                                                  </Modal>
                                                )}
                                                <Button
                                                  type="button"
                                                  variant="warning"
                                                  size="sm"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowCategoryUpdate(
                                                      category._id
                                                    );
                                                    setUpdateCategoryInput(
                                                      category.name
                                                    );
                                                  }}
                                                  className="mx-2"
                                                >
                                                  Kategoriyi Düzenle
                                                </Button>
                                                {showCategoryUpdate ===
                                                  category._id && (
                                                  <Modal
                                                    show={showCategoryUpdate}
                                                    onHide={
                                                      handleCategoryUpdateClose
                                                    }
                                                  >
                                                    <Modal.Header closeButton>
                                                      <Modal.Title>
                                                        Kategoriyi Düzenle
                                                      </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                      <Form>
                                                        <Form.Group
                                                          className="mb-3"
                                                          controlId="exampleForm.ControlInput1"
                                                        >
                                                          <Form.Label>
                                                            Kategori Adı
                                                          </Form.Label>
                                                          <Form.Control
                                                            type="text"
                                                            defaultValue={
                                                              category.name
                                                            }
                                                            onChange={(e) => {
                                                              setUpdateCategoryInput(
                                                                e.target.value
                                                              );
                                                            }}
                                                            autoFocus
                                                          />
                                                        </Form.Group>
                                                      </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                      <Button
                                                        variant="danger"
                                                        onClick={
                                                          handleCategoryUpdateClose
                                                        }
                                                      >
                                                        Kapat
                                                      </Button>
                                                      <Button
                                                        onClick={() => {
                                                          handleUpdateCategory(
                                                            category._id
                                                          );
                                                        }}
                                                        variant="success"
                                                      >
                                                        Güncelle
                                                      </Button>
                                                    </Modal.Footer>
                                                  </Modal>
                                                )}
                                                <Button
                                                  type="button"
                                                  variant="danger"
                                                  size="sm"
                                                  className="mx-2"
                                                  onClick={(e) => {
                                                    handleDeleteCategory(
                                                      category._id
                                                    );
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  Kategoriyi Sil
                                                </Button>
                                              </ButtonGroup>
                                            </div>
                                          </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          {/* category.subcategories.map((subCategory)  normalde bu şekildi ama sebebini anladığım bir nedenden dolayı kod patlıyo böyle olunca!!!*/}
                                          {category.subcategories.map(
                                            (subCategory) => {
                                              return (
                                                <div key={subCategory._id}>
                                                  <Accordion.Item
                                                    eventKey={subCategory._id}
                                                  >
                                                    <Accordion.Header>
                                                      <div class="w-100 d-flex justify-content-between align-items-center mx-2">
                                                        {subCategory.name}
                                                        <ButtonGroup>
                                                          <Button
                                                            size="sm"
                                                            variant="outline-info"
                                                            className="mx-2"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setShowElement(
                                                                subCategory._id
                                                              );
                                                            }}
                                                          >
                                                            Yeni Madde Ekle
                                                          </Button>
                                                          {showElement ===
                                                            subCategory._id && (
                                                            <Modal
                                                              show={showElement}
                                                              onHide={
                                                                handleElementClose
                                                              }
                                                            >
                                                              <Modal.Header
                                                                closeButton
                                                              >
                                                                <Modal.Title>
                                                                  Yeni Madde
                                                                  Ekle
                                                                </Modal.Title>
                                                              </Modal.Header>
                                                              <Modal.Body>
                                                                <Form>
                                                                  <Form.Group
                                                                    className="mb-3"
                                                                    controlId="exampleForm.ControlInput1"
                                                                  >
                                                                    <Form.Label>
                                                                      Madde Adı
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                      type="text"
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setNewElementName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                      autoFocus
                                                                    />
                                                                    <Form.Label>
                                                                      Karbon
                                                                      Ayak İzi
                                                                      Değeri
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                      type="number"
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setNewElementCfValue(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                      autoFocus
                                                                    />
                                                                    <Form.Label>
                                                                      Birimi
                                                                    </Form.Label>
                                                                    <Form.Select
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setNewElementSymbol(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                    >
                                                                      <option
                                                                        disabled
                                                                        selected
                                                                        value
                                                                      >
                                                                        Birim
                                                                        seçiniz
                                                                      </option>
                                                                      {symbolsData?.length >
                                                                        0 &&
                                                                        symbolsData.map(
                                                                          (
                                                                            symbol
                                                                          ) => (
                                                                            <option
                                                                              key={
                                                                                symbol._id
                                                                              }
                                                                              value={
                                                                                symbol._id
                                                                              }
                                                                            >
                                                                              {
                                                                                symbol.symbol
                                                                              }
                                                                            </option>
                                                                          )
                                                                        )}
                                                                    </Form.Select>
                                                                  </Form.Group>
                                                                </Form>
                                                              </Modal.Body>
                                                              <Modal.Footer>
                                                                <Button
                                                                  variant="danger"
                                                                  onClick={
                                                                    handleElementClose
                                                                  }
                                                                >
                                                                  Kapat
                                                                </Button>
                                                                <Button
                                                                  variant="success"
                                                                  onClick={() => {
                                                                    handleCreateElement(
                                                                      subCategory._id
                                                                    );
                                                                  }}
                                                                >
                                                                  Madde Ekle
                                                                </Button>
                                                              </Modal.Footer>
                                                            </Modal>
                                                          )}
                                                          <Button
                                                            type="button"
                                                            variant="warning"
                                                            size="sm"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setShowSubUpdate(
                                                                subCategory._id
                                                              );
                                                              setUpdateSubcategoryInput(
                                                                subCategory.name
                                                              );
                                                            }}
                                                            className="mx-2"
                                                          >
                                                            Alt Kategoriyi
                                                            Düzenle
                                                          </Button>
                                                          {showSubUpdate ===
                                                            subCategory._id && (
                                                            <Modal
                                                              show={
                                                                showSubUpdate
                                                              }
                                                              onHide={
                                                                handleSubUpdateClose
                                                              }
                                                            >
                                                              <Modal.Header
                                                                closeButton
                                                              >
                                                                <Modal.Title>
                                                                  Alt Kategoriyi
                                                                  Düzenle
                                                                </Modal.Title>
                                                              </Modal.Header>
                                                              <Modal.Body>
                                                                <Form>
                                                                  <Form.Group
                                                                    className="mb-3"
                                                                    controlId="exampleForm.ControlInput1"
                                                                  >
                                                                    <Form.Label>
                                                                      Alt
                                                                      Kategori
                                                                      Adı
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                      type="text"
                                                                      defaultValue={
                                                                        subCategory.name
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setUpdateSubcategoryInput(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        );
                                                                      }}
                                                                      autoFocus
                                                                    />
                                                                  </Form.Group>
                                                                </Form>
                                                              </Modal.Body>
                                                              <Modal.Footer>
                                                                <Button
                                                                  variant="danger"
                                                                  onClick={
                                                                    handleSubUpdateClose
                                                                  }
                                                                >
                                                                  Kapat
                                                                </Button>
                                                                <Button
                                                                  variant="success"
                                                                  onClick={() => {
                                                                    handleUpdateSubcategory(
                                                                      subCategory._id
                                                                    );
                                                                  }}
                                                                >
                                                                  Güncelle
                                                                </Button>
                                                              </Modal.Footer>
                                                            </Modal>
                                                          )}
                                                          <Button
                                                            type="button"
                                                            variant="danger"
                                                            size="sm"
                                                            className="mx-2"
                                                            onClick={(e) => {
                                                              handleDeleteSubcategory(
                                                                subCategory._id
                                                              );
                                                              e.stopPropagation();
                                                            }}
                                                          >
                                                            Alt Kategoriyi Sil
                                                          </Button>
                                                        </ButtonGroup>
                                                      </div>
                                                    </Accordion.Header>
                                                    <Table responsive>
                                                      <thead>
                                                        <th>Madde Adı</th>
                                                        <th>CF</th>
                                                        <th>Sembol</th>
                                                        <th></th>
                                                      </thead>
                                                      {subCategory.elements.map(
                                                        (element) => {
                                                          return (
                                                            <tbody
                                                              key={element._id}
                                                            >
                                                              <td
                                                                key={
                                                                  element.name
                                                                }
                                                              >
                                                                {element.name}
                                                              </td>
                                                              <td
                                                                key={element.CF}
                                                              >
                                                                {element.CF}
                                                              </td>
                                                              <td
                                                                key={
                                                                  element.symbol
                                                                    .symbol
                                                                }
                                                              >
                                                                {
                                                                  element.symbol
                                                                    .symbol
                                                                }
                                                              </td>
                                                              <td>
                                                                <ButtonGroup>
                                                                  <Button
                                                                    type="button"
                                                                    variant="warning"
                                                                    size="sm"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      setShowElementUpdate(
                                                                        element._id
                                                                      );
                                                                      setUpdateElementInput(
                                                                        element.name
                                                                      );
                                                                      setUpdateElementCfValue(
                                                                        element.CF
                                                                      );
                                                                      setUpdateElementSymbol(
                                                                        element
                                                                          .symbol
                                                                          ._id
                                                                      );
                                                                    }}
                                                                    className="mx-2 my-0"
                                                                  >
                                                                    Maddeyi
                                                                    Düzenle
                                                                  </Button>
                                                                  {showElementUpdate ===
                                                                    element._id && (
                                                                    <Modal
                                                                      show={
                                                                        showElementUpdate
                                                                      }
                                                                      onHide={
                                                                        handleElementUpdateClose
                                                                      }
                                                                    >
                                                                      <Modal.Header
                                                                        closeButton
                                                                      >
                                                                        <Modal.Title>
                                                                          Maddeyi
                                                                          Düzenle
                                                                        </Modal.Title>
                                                                      </Modal.Header>
                                                                      <Modal.Body>
                                                                        <Form>
                                                                          <Form.Group
                                                                            className="mb-3"
                                                                            controlId="exampleForm.ControlInput1"
                                                                          >
                                                                            <Form.Label>
                                                                              Madde
                                                                              Adı
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                              type="text"
                                                                              defaultValue={
                                                                                element.name
                                                                              } // placeholder={}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                setUpdateElementInput(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                              autoFocus
                                                                            />
                                                                            <Form.Label>
                                                                              Karbon
                                                                              Ayak
                                                                              İzi
                                                                              Değeri
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                              type="number"
                                                                              defaultValue={
                                                                                element.CF
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                setUpdateElementCfValue(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                              autoFocus
                                                                            />
                                                                            <Form.Label>
                                                                              Birimi
                                                                            </Form.Label>
                                                                            <Form.Select
                                                                              defaultValue={
                                                                                element
                                                                                  .symbol
                                                                                  ._id
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                setUpdateElementSymbol(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                );
                                                                              }}
                                                                            >
                                                                              {symbolsData?.length >
                                                                                0 &&
                                                                                symbolsData.map(
                                                                                  (
                                                                                    symbol
                                                                                  ) => (
                                                                                    <option
                                                                                      key={
                                                                                        symbol._id
                                                                                      }
                                                                                      value={
                                                                                        symbol._id
                                                                                      }
                                                                                    >
                                                                                      {
                                                                                        symbol.symbol
                                                                                      }
                                                                                    </option>
                                                                                  )
                                                                                )}
                                                                            </Form.Select>
                                                                          </Form.Group>
                                                                        </Form>
                                                                      </Modal.Body>
                                                                      <Modal.Footer>
                                                                        <Button
                                                                          variant="danger"
                                                                          onClick={
                                                                            handleElementUpdateClose
                                                                          }
                                                                        >
                                                                          Kapat
                                                                        </Button>
                                                                        <Button
                                                                          variant="success"
                                                                          onClick={() => {
                                                                            handleUpdateElement(
                                                                              element._id
                                                                            );
                                                                          }}
                                                                        >
                                                                          Güncelle
                                                                        </Button>
                                                                      </Modal.Footer>
                                                                    </Modal>
                                                                  )}
                                                                  <Button
                                                                    type="button"
                                                                    variant="danger"
                                                                    size="sm"
                                                                    className="mx-2"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      handleDeleteElement(
                                                                        element._id
                                                                      );
                                                                      e.stopPropagation();
                                                                    }}
                                                                  >
                                                                    Maddeyi Sil
                                                                  </Button>
                                                                </ButtonGroup>
                                                              </td>
                                                            </tbody>
                                                          );
                                                        }
                                                      )}
                                                    </Table>
                                                  </Accordion.Item>
                                                </div>
                                              );
                                            }
                                          )}
                                          {/* buradan emin değilim */}
                                          {/* <Accordion.Item eventKey={subCategories.id}>
                                          <Accordion.Header>
                                            <div class="w-100 d-flex justify-content-between align-items-center">
                                              {subCategories.name}
                                              <div class="d-flex">
                                                <Button size="sm" variant="outline-info">Yeni Madde Ekle</Button>
                                              </div>
                                            </div> 
                                          </Accordion.Header>
                                        </Accordion.Item> */}
                                          {/* // */}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </div>
                                  );
                                })}
                              </Accordion.Body>
                            </Accordion.Item>
                          </div>
                        );
                      })}
                  </Accordion>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
      <SideBar />

      <Accordion />
    </>
  );
};

export default AdminMainPage;
