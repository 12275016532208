import React, { useEffect } from "react";

import icon from "../assets/images/profile.png";
import UserSideBar from "../Components/UserSideBar";
import { useQuery } from "@tanstack/react-query";
import { me } from "../axios";
import TopBar from "../Components/TopBar";
import UserTopBar from "../Components/UserTopBar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Line, Pie, Bar } from "react-chartjs-2";
const UserPage = () => {
  const { data } = useQuery(["me"], () => me().then((res) => res.data));

  ChartJS.register(ArcElement, Tooltip, Legend);

  const chartdata = {
    labels: ["Enerji", "Ulaşım", "Orman", "Kimyasal", "Ambalaj", "Gıda"],
    datasets: [
      {
        label: "Carbonfootprint Values",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <UserTopBar></UserTopBar>
      <div class="container-fluid">
        <div class="row">
          <UserSideBar />
          <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 30,
                  }}
                >
                  <div class="h2">Ana Sayfa</div>
                  <div class=" mb-2 mb-md-0 ml-2 align-items-center">
                    <div class="btn-toolbar mb-2 mb-md-0 ml-2 align-items-center"></div>
                  </div>
                </div>
                <div class="container-fluid">
                  <div class="col-md-12">
                    <div class="row border-color">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <div class="row col-md-12 mt-3 border border-black rounded d-flex align-items-center ">
                      <div
                        className="chart"
                        class="col-md-6 mb-5 mt-5 mh-50"
                        style={{
                          height: 350,
                          marginLeft: "10%",
                          marginRight: "-10%",
                        }}
                      >
                        <Pie
                          ref={null}
                          data={chartdata}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: { position: "top" },
                              title: {
                                display: true,
                                text: "Chart.js Pie Chart",
                              },
                            },
                          }}
                        />
                      </div>
                      <div
                        class="col-md-6 mb-5 mt-5 mh-50"
                        style={{ height: 350 }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    <div class="h2 py-4">Önerilere Gözat</div>
                    <div class="px-4">
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default UserPage;
