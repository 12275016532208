import React from "react";
import NavBar from "../Components/Nav";
import eraLogo from "../assets/images/eraLogo.png";
import forest from "../assets/images/forest.jpeg";
import Footer from "../Components/Footer";

const AboutPage = () => {
  return (
    <main>
      <NavBar />
      <br />
      <div class="container">
        <div class="row featurette">
          <div class="col-md-7 mt-4">
            <h2 class="featurette-heading fw-normal lh-1 mt-3">
              Hakkımızda
            </h2>
            <p class="lead">
              ERA (Emission Reduction Application), CANSET Yazılım Enerji Çevre
              Danışmanlık Ticaret A.Ş. tarafından geliştirilmiş bir uygulamadır.
              Uygulamamız, kurumların sera gazı salımlarını kendi tüketim
              verileri üzerinden anlık olarak takip etmelerini sağlar. Ayrıca,
              kullanıcılara karbon ayak izini düşürme amaçlı hedefe yönelik
              stratejiler önerir ve sonuç grafikleri içeren raporlar hazırlar.
              Firmamız da bu stratejiler ışığında, kullanıcılara ayrıntılı bir
              yol haritası hazırlayıp süreç geliştirme danışmanlığı hizmeti
              verir. 
            </p>
            <br/>
            <p class="lead">
            CANSET Yazılım Enerji Çevre Danışmanlık Ticaret A.Ş,
              kurumlara yönelik sürdürülebilirlik danışmanlığı hizmeti veren,
              çevresel etki ölçüm/raporlaması yapan ve kurumlara süreç
              iyileştirme önerileri sunan bir ARGE ve danışmanlık firmasıdır.
            </p>
          </div>
          <div class="col-md-5 mt-5">
            <img
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
              src={eraLogo}
              width={400}
              height={300}
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <Footer />
      </div>
    
    </main>
  );
};

export default AboutPage;
