import React from "react";
import { Link } from "react-router-dom";
import { logout, me } from "../axios";
import { useQueryClient } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
const UserSideBar = () => {
  const queryClient = useQueryClient();
  return (
    <>
      <nav
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-md-block sidebar collapse"
        style={{ backgroundColor: "#191c1f" }}
      >
        <div class="position-sticky pt-3 sidebar-sticky">
          <ul
            class="nav d-flex mx-auto flex-column"
            style={{ alignItems: "flex-start" }}
          >
            <li class="nav-item" style={{ cursor: "pointer" }}>
              <div class="sidebar-item text-white">
                <FeatherIcon
                  icon="home"
                  class="icon"
                  style={{ paddingRight: 9 }}
                />
                <span class="text-white">Anasayfa</span>
              </div>
            </li>
            {/* <li class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="user-plus" class="icon" style={{paddingRight:5}}  />
                <Link className="text-decoration-none" to="/newData">
                  <span class="text-white">Hesapla</span>
                </Link>
              </div>
            </li> */}
            <li id="sidebar-exit-button" class="nav-item text-white">
              <div class="sidebar-item active">
                <FeatherIcon icon="user-plus" class="icon" />
                <Link className="text-decoration-none" to="/adminUserList">
                  <span class="text-white">Çıkış Yap</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default UserSideBar;
