import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { addElements, fetchElements, postCalculation } from "../axios";
import { errorToast, successToast } from "../functions/toast";
import { Table } from "react-bootstrap";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

export const NewDataComponent = ({
  owner,
  newCalculationCallback,
  currentCalculation,
}) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    calculationName: "",
  });

  const { isFetching, data } = useQuery(["elements"], () =>
    fetchElements().then((res) => res.data)
  );
  const [selectedEmission, setSelectedEmission] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [selectedElement, setSelectedElement] = useState();

  const [carbonfootprint, setCarbonfootprint] = useState();
  const [calculations, setCalculations] = useState([]);

  const deleteComponent = (index) => {
    setCalculations(
      calculations.filter((calc) => calc !== calculations[index])
    );
  };

  const createCalculationMutation = useMutation(
    (payload) => postCalculation(payload),
    {
      onSuccess: () => {
        successToast("Hesaplama gönderme işlemi başarılı.");
      },
      onError: () => {
        errorToast("Hesaplama gönderme işlemi başarısız, tekrar deneyin.");
      },
    }
  );

  const addElementsMutation = useMutation((payload) => addElements(payload), {
    onSuccess: () => {
      successToast("Maddeler başarıyla eklendi.");
    },
    onError: () => {
      errorToast("Madde ekleme işlemi başarısız tekrar deneyin.");
    },
  });

  const handleSendCalculations = async () => {
    const { data } = await createCalculationMutation.mutateAsync({
      calculationName: formData.calculationName,
      owner: owner ? owner : null,
      value: calculations.reduce(
        (acc, item) => acc + item.calculation * item.element?.CF ?? 0,
        0
      ),
      calculationItems: calculations.map((item) => ({
        _id: item.id,
        quantity: item.calculation,
        emission: item.emission,
        category: item.category,
        subcategory: item.sub,
      })),
    });
    queryClient.refetchQueries("calcs");
    setFormData({ calculationName: "" });
    setCalculations([]);
    if (newCalculationCallback) {
      newCalculationCallback();
    }
  };

  const handleAddElements = async () => {
    const { data } = await addElementsMutation.mutateAsync({
      id: currentCalculation._id,
      value: calculations.reduce(
        (acc, item) => acc + item.calculation * item.element?.CF ?? 0,
        0
      ),
      calculationItems: calculations.map((item) => ({
        _id: item.id,
        quantity: item.calculation,
        emission: item.emission,
        category: item.category,
        subcategory: item.sub,
      })),
    });
    queryClient.refetchQueries("calcs");
    setFormData({ calculationName: "" });
    setCalculations([]);
    if (newCalculationCallback) {
      newCalculationCallback();
    }
  };

  return (
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="row">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 30,
              }}
            >
              {/* <div>{data?.user?.fullName}</div> */}
              <div class=" mb-2 mb-md-0 ml-2 align-items-center">
                <h1 class="h2">Hesapla</h1>
                <div class="btn-toolbar mb-2 mb-md-0 ml-2 align-items-center"></div>
              </div>
              {/* 
                <Button variant="success" size="md" onClick={handleAdd}>
                    <FeatherIcon icon="plus-square" />
                    Hesaplama Ekle
                </Button> */}
            </div>

            <div class="card">
              <div
                class="col-md-5 input-group d-flex justify-between p-4 overflow-auto"
                style={{
                  marginTop: 20,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  borderStyle: "solid",
                  borderColor: "#097969",
                }}
              >
                <div class=" pt-3">
                  <select
                    className="custom-select form-select category-select"
                    id="emission"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setSelectedEmission(e.target.value);
                      setSelectedCategory();
                      document.getElementById(
                        "category"
                      ).options.selectedIndex = 0;
                    }}
                  >
                    <option value selected disabled>
                      Bir kategori seçin
                    </option>
                    {data?.map((emission, index) => {
                      return (
                        <option key={index} value={JSON.stringify(emission)}>
                          {emission.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div class=" py-3">
                  <select
                    className="custom-select form-select category-select"
                    id="category"
                    style={{ width: "100%" }}
                    disabled={!selectedEmission}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      setSelectedSubCategory();
                      document.getElementById(
                        "subcategory"
                      ).options.selectedIndex = 0;
                    }}
                  >
                    <option value selected disabled>
                      Bir kategori seçin
                    </option>
                    {selectedEmission &&
                      JSON.parse(selectedEmission)?.categories?.map(
                        (category, index) => {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(category)}
                            >
                              {category.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>
                <div class=" pb-3">
                  <select
                    className="form-select custom-select w-100"
                    id="subcategory"
                    disabled={!selectedCategory}
                    onChange={(e) => {
                      setSelectedSubCategory(e.target.value);
                      setSelectedElement();
                      document.getElementById(
                        "element"
                      ).options.selectedIndex = 0;
                    }}
                  >
                    <option value selected disabled>
                      Bir yan kategori seçin
                    </option>
                    {selectedCategory &&
                      JSON.parse(selectedCategory)?.subcategories?.map(
                        (subcategory, index) => {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(subcategory)}
                            >
                              {subcategory.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>
                <div class="pb-3">
                  <select
                    class="form-select custom-select w-100"
                    id="element"
                    disabled={!selectedSubCategory}
                    onChange={(e) => {
                      setSelectedElement(e.target.value);
                    }}
                  >
                    <option value selected disabled>
                      Bir madde seçin
                    </option>
                    {selectedSubCategory &&
                      JSON.parse(selectedSubCategory)?.elements?.map(
                        (elements, index) => {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify(elements)}
                            >
                              {elements.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ padding: 6 }} class="d-flex align-items-center">
                    <input
                      class="form-control input-group-sm mb-3"
                      placeholder="Miktar Giriniz"
                      id="cf"
                      onChange={(e) => {
                        setCarbonfootprint(e.target.value);
                      }}
                    ></input>
                    <h6 class="px-2">
                      {selectedElement &&
                        JSON.parse(selectedElement)?.symbol?.symbol}
                    </h6>

                    {/* {selectedElement &&
            JSON.parse(selectedElement)?.symbol?.map((symbol) => {
                return (
                <tbody key={symbol._id}>
                    <td key={symbol.symbol}>{symbol.symbol}</td>
                </tbody>
                );
            })} */}
                  </div>
                  <button
                    disabled={!selectedElement || !carbonfootprint}
                    class="btn btn-outline-primary m-2"
                    type="submit"
                    onClick={() => {
                      setCarbonfootprint(carbonfootprint);
                      setCalculations((oldArray) => [
                        ...oldArray,
                        {
                          id: JSON.parse(selectedElement)._id,
                          calculation: carbonfootprint,
                          emission: JSON.parse(selectedEmission),
                          category: JSON.parse(selectedCategory),
                          sub: JSON.parse(selectedSubCategory),
                          element: JSON.parse(selectedElement),
                        },
                      ]);
                      document.getElementById(
                        "emission"
                      ).options.selectedIndex = 0;

                      document.getElementById(
                        "category"
                      ).options.selectedIndex = 0;

                      document.getElementById(
                        "subcategory"
                      ).options.selectedIndex = 0;

                      document.getElementById(
                        "element"
                      ).options.selectedIndex = 0;

                      document.getElementById("cf").value = 0;

                      setSelectedEmission();
                      setSelectedCategory();
                      setSelectedElement();
                      setSelectedSubCategory();

                      setCarbonfootprint(0);
                    }}
                  >
                    Ekle
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class=" d-flex col-md-4 justify-content-between flex-wrap flex-md-nowrap align-items-center mx-4 pt-3 pb-2 mb-3 mt-4  ">
            <div class="w-100 card p-4 border border-5  ">
              <div class="text-uppercase fs-5">Karbon Değerleri</div>
              <ul class="list-group list-group-flush">
                <div class="d-flex">
                  <Table bordered>
                    <thead>
                      <th>Madde Adı</th>
                      <th>Karbon Değeri</th>

                      <th></th>
                    </thead>{" "}
                    {calculations.map((calc, index) => (
                      <tbody key={index}>
                        <td> {calc.element?.name}</td>
                        <td>
                          {(calc.calculation * calc.element?.CF)
                            .toString()
                            .substring(0, 6)}
                        </td>
                        <td>
                          <div
                            key={calc.id}
                            onClick={() => deleteComponent(index)}
                          >
                            <FeatherIcon
                              icon="x"
                              style={{
                                width: 24,
                                height: 24,
                                color: "red",
                              }}
                            />
                          </div>
                        </td>
                      </tbody>
                    ))}
                  </Table>
                  {/* <li key={calc.id}>
                        {"Değer :"}
                        {calc.calculation * calc.element?.CF}
                        {" --- "}
                        {calc.category?.name}
                        {" --- "}
                        {calc.element?.name}
                        {" --- "}
                        {calc.element?.CF} {calc.element?.symbol?.symbol}
                        </li> */}
                </div>
              </ul>
              {calculations.length === 0 ? (
                ""
              ) : (
                <>
                  <div>
                    Toplam değer :
                    {calculations
                      .reduce(
                        (acc, item) =>
                          acc + item.calculation * item.element?.CF ?? 0,
                        0
                      )
                      .toString()
                      .substring(0, 6)}
                  </div>
                  {!currentCalculation && (
                    <input
                      className="mt-2 p-1 mx-2 border border-2 rounded"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          calculationName: e.target.value,
                        });
                      }}
                      //type="email"
                      placeholder="Hesaplama başlığı girin "
                    />
                  )}

                  {currentCalculation ? (
                    <button
                      disabled={calculations.length < 1}
                      class="btn btn-outline-primary m-2"
                      type="submit"
                      onClick={handleAddElements}
                    >
                      Ekle
                    </button>
                  ) : (
                    <button
                      disabled={!formData.calculationName}
                      class="btn btn-outline-primary m-2"
                      type="submit"
                      onClick={handleSendCalculations}
                    >
                      Gönder
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
