import React, { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { fetchFuelItems } from "../axios";
import { useQuery } from "@tanstack/react-query";

const PhotovoltaicPanelSolution = ({ location, calculation }) => {
  const { data, isFetching } = useQuery(["fuelItems"], () =>
    fetchFuelItems()
      .then((res) => res.data)
      .then((data) => data.filter((item) => item.name === "Güneş Paneli"))
  );

  const [alan, setAlan] = useState(0);
  const [reduction, setReduction] = useState(0);

  useEffect(() => {
    console.log("Location: ", location);
  }, [location]);

  return (
    <>
      {isFetching && data?.length !== 1 ? (
        <Spinner />
      ) : (
        <Card className="bg-light">
          <Card.Header>
            <h3>Fotovoltanik panel ile enerji üretimi</h3>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3 h5" controlId="formPhoneNumber">
                <Form.Label className="d-flex justify-content-start text-black">
                  Güneş paneli kurulumu için çatı alanı (m²)
                </Form.Label>
                <Form.Control
                  value={alan}
                  onChange={(e) => {
                    setAlan(e.target.value);
                    setReduction(0.2 * e.target.value * location.R * 0.4092);
                  }}
                  type="number"
                  className="text-black"
                />
              </Form.Group>
            </Form>
            {alan > 0 && (
              <div className="d-flex flex-column">
                <div className="d-flex align-content-center">
                  <h5 className="text-black">
                    Güneş panelinden üretilebilecek yıllık enerji miktarı (kWh):{" "}
                  </h5>
                  <h5 className="text-black" style={{ marginLeft: 10 }}>
                    <strong>{(0.2 * alan * location.R).toFixed(2)}</strong>
                  </h5>
                </div>

                <p style={{ textAlign: "left" }}>
                  <strong>NOT: </strong>bu değer firmanızın bağlı bulunduğu
                  konum esas alınarak hesaplanmış olup firmanıza ait binanın
                  etrafındaki olası ışık kesici unsurları (dağ, tepe gibi
                  coğrafi şekiller, yüksek başka binalar, vs.) göz önüne
                  almamaktadır. Daha kesin sonuç veren detaylı bir analiz
                  yaptırmak isterseniz firmamız yetkilileri size yardımcı
                  olmaktan mutluluk duyacaklardır
                </p>
                <hr />

                <div className="d-flex align-content-center">
                  <h5 className="text-black">
                    Güneş panelinin yaklaşık maliyeti (TL):{" "}
                  </h5>
                  <h5 className="text-black" style={{ marginLeft: 10 }}>
                    <strong>{(data[0].price * alan).toFixed(2)}</strong>
                  </h5>
                </div>
                <hr />

                <div className="d-flex align-content-center">
                  <h5 className="text-black" style={{ textAlign: "left" }}>
                    Güneş paneli kullanmaktan sağlanacak yıllık sera gazı salım
                    azaltımı (kg CO₂):{" "}
                  </h5>
                  <h5 className="text-black" style={{ marginLeft: 10 }}>
                    <strong>{reduction.toFixed(2)}</strong>
                  </h5>
                </div>
                <hr />

                <div className="d-flex align-content-center">
                  <h5 className="text-black">
                    Sera gazı salımında yüzdesel azalma:{" "}
                  </h5>
                  <h5 className="text-black" style={{ marginLeft: 10 }}>
                    <strong>
                      %
                      {(100 -
                        ((calculation.value - reduction) * 100) /
                          calculation.value >
                      100
                        ? 100
                        : 100 -
                          ((calculation.value - reduction) * 100) /
                            calculation.value
                      ).toFixed(2)}
                    </strong>
                  </h5>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PhotovoltaicPanelSolution;
