import { useQuery, useQueryClient } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import logo from "../assets/images/LogoWithoutNameTransparentWhite.png";
import logo2 from "../assets/images/logoTextWhiteNoBack.png";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout, me } from "../axios";
const UserTopBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { data } = useQuery(["me"], () => me().then((res) => res.data));
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {}, [data]);

  return (
    <header
      class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow d-flex align-items-center py-1"
      style={{ backgroundColor: "#191c1f" }}
    >
      <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-4">
        <img src={logo} class="bd-placeholder-img" width="40" />
        <img src={logo2} class="bd-placeholder-img mt-1 mx-2" height="35" />
      </a>

      <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => {
          if (showMenu) {
            document.getElementById("sidebarMenu").classList.remove("show");
            setShowMenu(!showMenu);
          } else {
            document.getElementById("sidebarMenu").classList.add("show");
            setShowMenu(!showMenu);
          }
        }}
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="d-flex p-2 align-items-center ">
        <div id="username-user-icon" class="px-4 d-flex align-items-center">
          <div class="px-3" style={{ fontWeight: 700, color: "white" }}>
            {data?.fullName
              ? data.fullName
              : data?.user.fullName
              ? data.user.fullName
              : ""}
          </div>
          <FeatherIcon
            icon="user"
            style={{ width: 36, height: 36, color: "white" }}
            class="icon"
          />
        </div>
        <div id="topbar-exit-button" class="navbar-nav">
          <div class="nav-item text-nowrap bg-black">
            <a class="nav-link px-3">
              <Link
                onClick={async (e) => {
                  await logout();
                  queryClient.setQueryData(["me"], null);
                  navigate("/");
                }}
                className="text-decoration-none text-white"
              >
                Çıkış yap
              </Link>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UserTopBar;
