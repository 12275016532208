import React from "react";
import UserSideBar from "../Components/UserSideBar";
import UserTopBar from "../Components/UserTopBar";
import { NewDataComponent } from "../Components/NewDataComponent";

const UserNewData = ({ props }) => {
  return (
    <>
      <UserTopBar></UserTopBar>
      <div class="container-fluid">
        <div class="row">
          <UserSideBar />
          <NewDataComponent />
        </div>
      </div>
    </>
  );
};

export default UserNewData;
